export const CONTACTS_LIST_REQUEST = 'CONTACTS_LIST_REQUEST';
export const CONTACTS_LIST_SUCCESS = 'CONTACTS_LIST_SUCCESS'; 
export const CONTACTS_LIST_FAIL = 'CONTACTS_LIST_FAIL';
export const CONTACTS_SAVE_REQUEST = 'CONTACTS_SAVE_REQUEST';
export const CONTACTS_SAVE_SUCCESS = 'CONTACTS_SAVE_SUCCESS';
export const CONTACTS_SAVE_FAIL = 'CONTACTS_SAVE_FAIL'; 
export const CONTACTS_DELETE_REQUEST = 'CONTACTS_DELETE_REQUEST';
export const CONTACTS_DELETE_SUCCESS = 'CONTACTS_DELETE_SUCCESS';
export const CONTACTS_DELETE_FAIL = 'CONTACTS_DELETE_FAIL';
export const SELECTED_CONTACT = 'SELECTED_CONTACT';
export const CONTACTS_SIDEBAR_IS_TOGGLED = 'CONTACTS_SIDEBAR_IS_TOGGLED';
import React, { useEffect } from 'react';
import {  useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { pathListner } from '../../actions/pathActions';

import { listMenus, selectedMenu, toggleMenusSidebar } from '../../actions/menusActions';
import MenusFormSidebar from './MenusFormSidebar';
import MenusFiltre from './MenusFiltre';
import MenusDatatable from './MenusDatatable';
import useHasPermission from '../../hooks/useHasPermission'

export default function Menus() {

    const dispatch = useDispatch();
    const canCreate = useHasPermission("/menus", "create");

    useEffect(() => {
        window.initPlugins();
        dispatch(pathListner('/menus'));
        dispatch(listMenus());

    }, []);

    const addMenu = () => {
        dispatch(selectedMenu(null));
        dispatch(toggleMenusSidebar(true));
    }

    return <React.Fragment>

        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />


        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="col s12">
                <div className="container">

                    {/* <!-- Add new User popup --> */}
                    <div className="contact-overlay"></div>
                    {
                        canCreate ? (
                            <div style={{ bottom: '54px', right: '19px' }} className="fixed-action-btn direction-top">
                                <a className="btn-floating btn-large primary-text gradient-shadow contact-sidebar-trigger" onClick={addMenu}>
                                    <i className="material-icons">playlist_add</i>
                                </a>
                            </div>
                        ) : undefined
                    }
                    
                    {/* <!-- Add new User popup Ends--> */}

                    <MenusFiltre />

                    <MenusDatatable />

                    <MenusFormSidebar />

                </div>
                <div className="content-overlay"></div>
            </div>
        </div>

    </React.Fragment>;
}


import React, { useEffect} from 'react'

// packages 
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import {Link} from 'react-router-dom'
import Select from 'react-select'
import { customStyles } from '../../styles/reactSelect'
import useHasPermission from '../../hooks/useHasPermission'
// actions 
import { listMenuItems, setMenuId, setMenuLangId, setMenuItems } from '../../actions/MenuItemsActions';

import { _trans } from '../../actions/locoBOActions';

export default function MenuItemTypeSideBar() {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO)

    const dispatch = useDispatch();
    const storeItems = useSelector(state => state.menuItems);
    const menusList = useSelector(state => state.menus);
    const languagesList = useSelector(state => state.languagesList);
    const { languages, loading: langsLoading,error : langsError } = languagesList;
    const { menuId } = useSelector(state => state.menuSelected);
    const { menuLangId } = useSelector(state => state.menuLangSelected);
    const canCreate = useHasPermission("/menu-items", "create");
    const canUpdate = useHasPermission("/menu-items", "update");
    const { menus,
        loading,
        error } = menusList;

    const menuOptions = []
    menus.filter(cat => {
        let option = {
            label: cat.menus_title,
            value: cat.id
        }
        menuOptions.push(option);
    })
    const languageOptions = []
    languages.filter(cat => {
        let option = {
            label: cat.langs_title,
            value: cat.id
        }
        languageOptions.push(option);
    })
    useEffect(() => {
        window.initPlugins();
    },[])
    const addNewItem =(type)=>{
        const item = {
            title: "",
            type: type,
            output: "LINK",
            payload: "",
            className: ""
        }

        let items = [...storeItems.menuItems];
        items.push(item);
        dispatch(setMenuItems(items))
    }
    const handelMenuChange =(val)=>{
        dispatch(setMenuId(val))
        dispatch(listMenuItems(val, menuLangId, (menusListItem) => {
            const items = menusListItem.items ? menusListItem.items : [];
            dispatch(setMenuItems(items))
        }))
    }
    const handelLangChange =(val)=>{
        dispatch(setMenuLangId(val))
        dispatch(listMenuItems(menuId, val, (menusListItem) => {
            const items = menusListItem.items ? menusListItem.items : [];
            dispatch(setMenuItems(items))
        }))
    }
    return (
       <React.Fragment>
            <div className="card-panel mb-3">
                <div className="row">
                    { loading ? <Skeleton count={1} height={50} /> : (
                            <div className="input-field col m12 s12">
                                <Select
                                    name="menu_id"
                                    id="menu_id"
                                    value={menuOptions.filter(opt => { return (opt.value === menuId) })}
                                    onChange={(opt, e) => {
                                        handelMenuChange(opt?.value)
                                    }}
                                    blurInputOnSelect={true}
                                    placeholder=""
                                    isSearchable={true}
                                    isClearable={true}
                                    styles={customStyles}
                                    options={menuOptions}
                                />
                                <label htmlFor="categorie" className="active">{_trans('Selected menu',locosBO)}</label>
                            </div>
                    )}
                    <Link to="/menus" className="mb-3 mt-3 col m12 s12"><i className="material-icons left">add_circle</i>{_trans('Add menu',locosBO)}</Link>
                </div>
                <div className="row">
                    {langsLoading ? <Skeleton count={1} height={50} /> : (
                            <div className="input-field col m12 s12">
                                <Select
                                    name="id_lang"
                                    id="id_lang"
                                    value={languageOptions.filter(opt => { return (opt.value === menuLangId) })}
                                    onChange={(opt, e) => {
                                        handelLangChange(opt?.value)
                                    }}
                                    blurInputOnSelect={true}
                                    placeholder=""
                                    isSearchable={true}
                                    isClearable={true}
                                    styles={customStyles}
                                    options={languageOptions}
                                />
                            <label htmlFor="Languages" className="active">{_trans('Selected language',locosBO)} </label>
                            </div>
                    )}
                    <Link to="/languages" className="mb-3 mt-3 col m12 s12"><i className="material-icons left">add_circle</i>{_trans('Add language',locosBO)}</Link>
                </div>
            </div>
            {
                canCreate && canUpdate?(
                    <div className="card-panel">
                        <ul className="tabs">
                            <li className="tab ui-tab-item">
                                <div>
                                    <i className="material-icons">insert_link</i>
                                    <span>{_trans('Simple Url',locosBO)}</span>
                                </div>
                                <button className="btn-small" onClick={() => { addNewItem("url") }}><i className="material-icons dp48">add_box</i></button>
                            </li>
                            <li className="tab ui-tab-item">
                                <div>
                                    <i className="material-icons">pages</i>
                                    <span>{_trans('Post Type',locosBO)}</span>
                                </div>
                                <button className="btn-small" onClick={() => { addNewItem('post') }} ><i className="material-icons dp48">add_box</i></button>
                            </li>
                            <li className="tab ui-tab-item">
                                <div>
                                    <i className="material-icons">sort</i>
                                    <span>{_trans('Category',locosBO)}</span>
                                </div>
                                <button className="btn-small" onClick={() => { addNewItem('category') }}><i className="material-icons dp48">add_box</i></button>
                            </li>

                            <li className="indicator"></li></ul>
                    </div>
                ):undefined
            }
         
       </React.Fragment>
    )
}

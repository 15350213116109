import React, { useEffect,useState} from 'react'

// packages 
import { useSelector, useDispatch } from 'react-redux';
import {  Field } from 'formik';

// actions 
import { listCategories } from '../../../actions/categoriesActions';

import { _trans } from '../../../actions/locoBOActions';

export default function CategoryField(props) {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const dispatch = useDispatch();
    const categoriesList = useSelector(state => state.categories);
    const { categories,loading } = categoriesList;

    let item = props.item;
    let output = "LINK";
    if (item) {
        output = item.output
    }
    const [title, setTitle] = useState("");
    const [className, setClassName] = useState("");
    const [dataType, setDataType] = useState(output);
    const [payload, setPayload] = useState("");

    useEffect(() => {
        dispatch(listCategories());
        setTitle(item.title);
        setClassName(item.className);
        setDataType(item.output);
        setPayload(item.payload);
    }, [item]);


    return (
        <React.Fragment>
            <div className="collapsible-body">
                <div className="row">
                   
                    {!loading && categories.length  ?(
                       <React.Fragment>
                            <div className="input-field col m12 s12">
                                <Field name={`items[${props.index}].title`} value={title} type="text" onChange={(e)=>{
                                item.title = e.target.value;
                                setTitle(e.target.value);
                                }}  />
                                <label htmlFor="name" className={title? "active" : ""}>{_trans('Title',locosBO)}</label>
                            </div>
                            <div className="input-field col m12 s12">
                                <Field name={`items[${props.index}].className`} type="text" value={className}  onChange={(e)=>{
                                    item.className = e.target.value;
                                    setClassName(e.target.value);
                                }}/>
                                <label htmlFor="name" className={className? "active" : ""}>{_trans('Class attribute',locosBO)}</label>
                            </div>
                            <div className="input-field col s12">
                                <p className="mb-2">{_trans('Data type',locosBO)}</p>
                                <p className="mb-1 display-inline mr-3">
                                    <label>
                                    <Field name={`items[${props.index}].output`}  checked={dataType==="LINK"}  type="radio" value="LINK" onChange={
                                        (e)=>{
                                            item.output = e.target.value;
                                            setDataType('LINK')
                                        }
                                    } />
                                        <span>{_trans('Simple Url',locosBO)}</span>
                                    </label>
                                </p>
                                <p className="mb-1 display-inline">
                                    <label>
                                    <Field name={`items[${props.index}].output`} checked={dataType==="OBJECT"} type="radio" value="OBJECT" onChange={
                                        (e)=>{
                                            item.output = e.target.value;
                                            setDataType('OBJECT')
                                        }
                                    }/>
                                        <span>{_trans('Data Object',locosBO)}</span>
                                    </label>
                                </p>
                             
                            </div>
                            <div className="input-field col m12 s12">
                                <Field component="select" value={payload}  name={`items[${props.index}].payload`}  onChange={(e)=>{
                                    item.payload = e.target.value;
                                    setPayload(e.target.value);
                                }}>
                                    <option value="" key="0" >{_trans('Select Cat',locosBO)}</option>
                                    {
                                        categories.map(function (item, i) {
                                            return <option  value={dataType=== "LINK" ? item.categories_slug : JSON.stringify(item)} key={i + 1} >{item.categories_title}</option>
                                        })
                                    }
                                </Field>
                                <label htmlFor="categorie" className='active'>{_trans('Category',locosBO)}</label>
                            </div>

                       </React.Fragment>
                    ):(
                        <div className="card-panel red  center">
                            <span className="white-text">{_trans('No category found',locosBO)} <i className="material-icons">error_outline</i></span>
                        </div>
                    )
                }
                </div>
            </div>
        </React.Fragment>
    )
}

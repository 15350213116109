import React, { useEffect} from 'react';
import {  useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { pathListner } from '../../actions/pathActions';

import { listAuthorizedApps, selectedAuthorizedApp, toggleAuthorizedAppsSidebar, listTrashedAuthorizedApps } from '../../actions/authorizedAppsActions';
import AuthorizedAppsFormSidebar from './AuthorizedAppsFormSidebar';
import AuthorizedAppsFiltre from './AuthorizedAppsFiltre';
import AuthorizedAppsDatatable from './AuthorizedAppsDatatable';
import FileManagerModal from '../FilesManager/FileManagerModal';
import useHasPermission from '../../hooks/useHasPermission'

export default function Authorized(props) {

    const dispatch = useDispatch();
    const canCreate = useHasPermission("/authorized-apps","create");

    useEffect(() => {

        dispatch(pathListner('/authorized-apps'));
        if (typeof props.match.params.trush != "undefined") {
            dispatch(listTrashedAuthorizedApps());
        }else{
            dispatch(listAuthorizedApps());
        }
        
    }, []);

    const addApp = () => {
        dispatch(selectedAuthorizedApp(null));
        dispatch(toggleAuthorizedAppsSidebar(true));
    }
    
    return <React.Fragment>

        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        <FileManagerModal />

        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="col s12">
                <div className="container">

                    {/* <!-- Add new User popup --> */}
                    <div className="contact-overlay"></div>
                 
                    {
                        canCreate ? (
                            <div style={{ bottom: '54px', right: '19px' }} className="fixed-action-btn direction-top">
                                <a className="btn-floating btn-large primary-text gradient-shadow contact-sidebar-trigger" onClick={addApp}>
                                    <i className="material-icons">add_circle</i>
                                </a>
                            </div>
                        ) : undefined
                    }
                    {/* <!-- Add new User popup Ends--> */}

                    <AuthorizedAppsFiltre props={props}/>

                    <AuthorizedAppsDatatable />

                    <AuthorizedAppsFormSidebar {...props}/>

                </div>
                <div className="content-overlay"></div>
            </div>
        </div>
        
        </React.Fragment>;
}


export const LOCOS_BO_LIST_REQUEST = 'LOCOS_BO_LIST_REQUEST';
export const LOCOS_BO_LIST_SUCCESS = 'LOCOS_BO_LIST_SUCCESS';
export const LOCOS_BO_LIST_FAIL    = 'LOCOS_BO_LIST_FAIL';

export const LOCOS_BO_TRANSLATE_REQUEST    = 'LOCOS_BO_TRANSLATE_REQUEST';
export const LOCOS_BO_TRANSLATE_SUCCESS    = 'LOCOS_BO_TRANSLATE_SUCCESS';
export const LOCOS_BO_TRANSLATE_FAIL    = 'LOCOS_BO_TRANSLATE_FAIL';

export const PATCH_LOCOS_BO_TRANSLATE_REQUEST    = 'PATCH_LOCOS_BO_TRANSLATE_REQUEST';
export const PATCH_LOCOS_BO_TRANSLATE_SUCCESS    = 'PATCH_LOCOS_BO_TRANSLATE_SUCCESS';
export const PATCH_LOCOS_BO_TRANSLATE_FAIL    = 'PATCH_LOCOS_BO_TRANSLATE_FAIL';
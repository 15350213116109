import React,{useState,useEffect} from 'react'
import { useSelector ,useDispatch} from 'react-redux'
import { Form, Field, Formik } from 'formik'
import * as yup from 'yup'
import { Link } from 'react-router-dom'
import { toggleFilesManagerModal, selectedFile } from '../../../actions/fileManagerActions';
import {  saveUser } from '../../../actions/usersActions';
import { toast } from 'react-toastify';

import { _trans } from '../../../actions/locoBOActions';

export default function UpdateInfosForm() {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 
    
    const dispatch = useDispatch();
    const contactSchema = yup.object().shape({
        users_name: yup.string().required(_trans('This field is required',locosBO)),
        users_email: yup.string().required(_trans('This field is required',locosBO)),
    })
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [id,setId] = useState(null);
    const { loading, user } = useSelector(state => state.login);
    const [avatar, setAvatar] = useState("/app-assets/images/user/default-avatar.png");
    const fileSelected = useSelector(state => state.selectedFile);

    useEffect(()=>{
        if (user) {
            const authUser=user.token.authUser;
            setName(authUser.users_name)
            setEmail(authUser.users_email)
            setId(authUser.id)
            if (authUser.users_image) {
                setAvatar(authUser.users_image)
            }
        }

        if (fileSelected.file) {
            setAvatar(fileSelected.file.path);
        }
    }, [user, fileSelected])

    const openFilesManagerModal = () => {
        dispatch(toggleFilesManagerModal(true));
    }
    return (
        <Formik
            validationSchema={contactSchema}
            initialValues={{
                id: id,
                users_name: name,
                users_email: email,
                users_image: avatar,
            }}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
                dispatch(saveUser(values, (response) => {
                    response.error ? toast.error(_trans('An error was detected',locosBO)) : toast.success(_trans('User info updated successfully',locosBO));
                }));
            }}
        >
            {({ errors, touched, isSubmitting, setFieldValue, setFieldTouched }) => (
                <Form id="accountForm">
                    <div className="media display-flex align-items-center mb-2">
                        <a className="mr-2" href="#">
                            <img src={avatar} alt="users avatar" className="z-depth-4 circle"
                                height="64" width="64" />
                        </a>
                        <div className="media-body">
                            <h5 className="media-heading mt-0">{_trans('Avatar',locosBO)}</h5>
                            <div className="user-edit-btns display-flex">
                                <a href="#" className="btn-small indigo" onClick={openFilesManagerModal}>{_trans('Change',locosBO)}</a>
                                <a href="#" className="btn-small btn-light-pink" onClick={() => { setAvatar("/app-assets/images/user/default-avatar.png") }}>{_trans('Reset',locosBO)}</a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m12">
                            <div className="row">
                                <div className="col s6 input-field">
                                    <Field id="users_name" name="users_name" type="text" className={errors.users_name ? "error validate" : "validate"} />
                                    <label htmlFor="users_name" className={name !== '' ? 'active' : ''}>{_trans('Name',locosBO)}</label>
                                    {errors.users_name && touched.users_name ? (
                                        <small className="errorTxt2"><div id="cemail-error" className="error">{errors.users_name}</div></small>
                                    ) : null}
                                </div>
                                <div className="col s6 input-field">
                                    <Field id="users_email" name="users_email" type="email" className={errors.users_email ? "error validate" : "validate"} />
                                    <label htmlFor="users_email" className={email !== '' ? 'active' : ''}>{_trans('Email',locosBO)}</label>
                                    {errors.users_email && touched.users_email ? (
                                        <small className="errorTxt2"><div id="cemail-error" className="error">{errors.users_email}</div></small>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 display-flex justify-content-end mt-3">
                        <button type="submit" className="btn indigo" disabled={isSubmitting}>{_trans('Edit',locosBO)}</button>
                        <button type="button" className="btn btn-light">{_trans('Cancel',locosBO)}</button>
                    </div>
                </Form>
            )}

        </Formik>
    )
}

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { _trans } from '../../actions/locoBOActions';

export default function PostTemplatesCount() {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const postTemplatesList = useSelector(state => state.postTemplatesList);
    const { postTemplates,
        loading,
        error } = postTemplatesList;

    useEffect(() => {

    }, [postTemplates]);

    return (
        <React.Fragment>
            {loading ? <div></div> :
                error ? <div>{error}</div> :

                    <p className="m-0 text-muted">{postTemplates.length} {_trans('Post Templates',locosBO)}</p>
            }
        </React.Fragment>
    );
}
const { LEADS_LIST_REQUEST, 
    LEADS_LIST_SUCCESS, 
    LEADS_LIST_FAIL,
    LEADS_SAVE_REQUEST,
    LEADS_SAVE_SUCCESS,
    LEADS_SAVE_FAIL, 
    LEADS_DELETE_REQUEST,
    LEADS_DELETE_SUCCESS,
    LEADS_DELETE_FAIL,
    SELECTED_LEAD,
    LEADS_SIDEBAR_IS_TOGGLED} = require("../constants/leadsConstants");

function leadsListReducer(state = {loading:true, leads:[]}, action){

switch(action.type){
    case LEADS_LIST_REQUEST:
        return {loading : true};
    case LEADS_LIST_SUCCESS:
        return {loading: false, leads: action.payload};
    case LEADS_LIST_FAIL:
        return {loading: false, error: action.payload};
    default:
        return state;
}

}

function leadsSaveReducer(state = {leads:[]}, action){

switch(action.type){
    case LEADS_SAVE_REQUEST:
        return {loading : true};
    case LEADS_SAVE_SUCCESS:
        return {loading: false, success: true, leads: action.payload};
    case LEADS_SAVE_FAIL:
        return {loading: false, error: action.payload};
    default:
        return state;
}

}

function leadsDeleteReducer(state = {leads:[]}, action){

switch(action.type){
    case LEADS_DELETE_REQUEST:
        return {loading : true};
    case LEADS_DELETE_SUCCESS:
        return {loading: false, success: true, leads: action.payload};
    case LEADS_DELETE_FAIL:
        return {loading: false, error: action.payload};
    default:
        return state;
}

}

function selectedLeadReducer(state = { lead: null }, action) {

switch (action.type) {
    case SELECTED_LEAD:
        return { lead: action.payload };
    default:
        return state;
}

}

function toggleLeadsSidebarReducer(state = { toggle: false }, action) {

switch (action.type) {
    case LEADS_SIDEBAR_IS_TOGGLED:
        return { toggle: action.payload };
    default:
        return state;
}

}


export {leadsListReducer, leadsSaveReducer, leadsDeleteReducer, selectedLeadReducer, toggleLeadsSidebarReducer}
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { listPostTypesCategories } from '../../actions/categoriesActions'
import { setSelectedPostCategorieItems } from '../../actions/postsActions'
import { Form, Formik, FieldArray, Field } from 'formik';
import { _trans } from '../../actions/locoBOActions';


export default function PostSidebar({post_type_id}) {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const dispatch = useDispatch();

    const { postTypesCategories, loading, error } = useSelector(state => state.postTypesCategories);
    const { selectedPostCategoriesItems } = useSelector(state => state.selectedPostCategoriesItems);

    useEffect(() => {
        dispatch(listPostTypesCategories(post_type_id));
    }, []);

    return (
        
        <React.Fragment>

            { loading && <div></div> }
            { error && <div>{error}</div> }
            {
                postTypesCategories ? (
                    <div className="sidebar-left sidebar-fixed">
                        <div className="sidebar">
                            {postTypesCategories.length ? (  
                            <div className="sidebar-content">
                                <div className="sidebar-header"></div>
                                <div id="sidebar-list" className="sidebar-menu list-group position-relative ps--active-y">
                                    <div className="sidebar-list-padding app-sidebar" id="contact-sidenav">

                                        <ul className="contact-list display-grid">
                                            <li className="sidebar-title">Categories</li>
                                        </ul>
                                        <Formik
                                            initialValues={{
                                                category_items: selectedPostCategoriesItems
                                            }}
                                            enableReinitialize={true}
                                        >
                                            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                                                <Form className="edit-contact-item mb-5 mt-5">
                                                    
                                                            <ul className="collapsible popout col s12" >
                                                                {
                                                                    postTypesCategories.map((category, i) => {
                                                                        return (
                                                                            <ul className="contact-list display-grid mb-4" key={"categorie-" + i}>
                                                                                <li className="active">
                                                                                    <a className="text-sub"><i className="material-icons mr-2">view_list </i> {category.categories_title}</a>
                                                                                </li>
                                                                                {
                                                                                    category.items ? (
                                                                                        category.items.map((item, j) => {
                                                                                            return (

                                                                                                <li className="pl-14" key={"key-item-"+j}>
                                                                                                    <p  className="text-sub mt-1 mb-1">
                                                                                                        <label>
                                                                                                            <Field type="checkbox" name="category_items"
                                                                                                                checked={values.category_items.includes(item.id.toString())}
                                                                                                                onChange={(event) => {
                                                                                                                    if (event.target.checked) {
                                                                                                                        values.category_items.push(item.id.toString());
                                                                                                                        setFieldValue('category_items', values.category_items);
                                                                                                                    } else {
                                                                                                                        let category_items = values.category_items.filter(category_item => {
                                                                                                                            return category_item !== item.id.toString()
                                                                                                                        })
                                                                                                                        values.category_items = category_items;
                                                                                                                        // setFieldValue('category_items', category_items);
                                                                                                                    }
                                                                                                                    dispatch(setSelectedPostCategorieItems(values.category_items))
                                                                                                                }}
                                                                                                                value={item.id.toString()} 
                                                                                                            />

                                                                                                            <span>{item.cat_item_title}</span>
                                                                                                        </label>
                                                                                                    </p>
                                                                                                </li>
                                                                                            )
                                                                                        })
                                                                                    ) : ''

                                                                                }
                                                                                
                                                                            </ul>
                                                                        )
                                                                    })
                                                                }
                                                                
                                                            </ul>
                                                </Form>
                                            )}

                                        </Formik>

                                    </div>
                                </div>
                            </div>
                            ) : "" }
                        </div>
                    </div>
                ): ""
                //_trans('No categories found!',locosBO)
            }
            
        
        </React.Fragment>
    )
}

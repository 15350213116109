import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const {
    LOCOS_BO_LIST_REQUEST,
    LOCOS_BO_LIST_SUCCESS,
    LOCOS_BO_LIST_FAIL,
    LOCOS_BO_TRANSLATE_REQUEST,
    LOCOS_BO_TRANSLATE_SUCCESS,
    LOCOS_BO_TRANSLATE_FAIL,
    PATCH_BO_LOCOS_TRANSLATE_REQUEST,
    PATCH_BO_LOCOS_TRANSLATE_SUCCESS,
    PATCH_BO_LOCOS_TRANSLATE_FAIL,
} = require("../constants/locosBOConstants");

const locosBOList = (cb) => async (dispatch) => {
    try {
        dispatch({ type: LOCOS_BO_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy + '/api/locos-bo',
            headers
        });
        typeof cb == "function" && cb(data)
        dispatch({ type: LOCOS_BO_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: LOCOS_BO_LIST_FAIL, payload: error.message });
    }

}

const getLocoBOTranslate = (loco,cb) => async (dispatch) => {
    try {
        dispatch({ type: LOCOS_BO_TRANSLATE_REQUEST });
        const { data } = await axios({
            method: 'post',
            url: apiProxy + "/api/locos-bo/translate",
            headers,
            data: loco
        });

        typeof cb == "function" && cb(data)
        dispatch({ type: LOCOS_BO_TRANSLATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: LOCOS_BO_TRANSLATE_FAIL, payload: error.message });
    }

}

const patchLocoBOTranslate = (loco,cb) => async (dispatch) => {
    try {
        dispatch({ type: PATCH_BO_LOCOS_TRANSLATE_REQUEST });
        const { data } = await axios({
            method: 'patch',
            url: apiProxy + "/api/locos-bo/" + loco.id,
            headers,
            data: loco
        });

        typeof cb == "function" && cb(data)
        dispatch({ type: PATCH_BO_LOCOS_TRANSLATE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: PATCH_BO_LOCOS_TRANSLATE_FAIL, payload: error.message });
    }

}

const storeNewLocoBO = (loco,cb) => async (dispatch) => {
    try {
        const { data } = await axios({
            method: 'post',
            url: apiProxy + "/api/locos-bo/",
            headers,
            data: loco
        });

        typeof cb == "function" && cb(data)
    } catch (error) {
        typeof cb == "function" && cb(error)
    }

}

const _trans = (text,locosBO) =>{
    return text;
}

export { locosBOList, getLocoBOTranslate, patchLocoBOTranslate, storeNewLocoBO,_trans }
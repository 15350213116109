import React, { useEffect, useState } from 'react';
// packages 
import { useSelector, useDispatch } from 'react-redux';
import { Form, Formik, FieldArray } from 'formik';
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';

// actions 
import { saveCategoryItem, setCategoryItems, listCategoryItems, deleteCategoryItem } from '../../actions/categoryItemsActions';

// components 
import ItemField from './itemField'
import useHasPermission from '../../hooks/useHasPermission'

import { _trans } from '../../actions/locoBOActions';

export default function CategoryItemsZone() {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    let history = useHistory();
    const dispatch = useDispatch();

    const [items, setItems] = useState([]);
    const [idCategory, setIdCategory] = useState([]);

    const { categoryItems } = useSelector(state => state.categoryItems);
    const { categoryId } = useSelector(state => state.categorySelected);
    const { currentLang } = useSelector(state => state.currentLang);
    const canCreate = useHasPermission("/category-items", "create");


    useEffect(() => {
        setIdCategory(categoryId);
        setItems(categoryItems);
        window.initPlugins();
    }, [categoryItems])

    const cancelHandler = () => {
        dispatch(setCategoryItems([]));
        history.push("/categories");
    }

    const addItemHandler = () => {
        
        const item = {
            cat_item_title: "",
            cat_item_slug: "",
            id_cat_item: "",
            id_lang: currentLang.id,
        }

        let items = [...categoryItems];
        items.push(item);
        dispatch(setCategoryItems(items))
    }

    return (
            !items ? (
                <div className="card-panel gradient-45deg-blue-grey-blue-grey center">
                    <span className="white-text"><i className="material-icons dp48">arrow_back</i> <br /> {_trans('Add category',locosBO)}</span>
                </div>
            ) :
                (
            <div className="card-panel">
                <Formik
                    initialValues={{
                    id_cat: idCategory,
                    items: items,
                    }}
                    enableReinitialize={true}
                    onSubmit={async (values, { resetForm }) => {
                        dispatch(saveCategoryItem(values, (resp) => {
                            if (!resp.error) {
                                toast.success('Categorie elements Enregistrer');
                                dispatch(listCategoryItems(categoryId, (items) => {
                                    dispatch(setCategoryItems(items))
                                }));
                            } else {
                                toast.error(resp.message);
                                window.location.reload();
                            }
                        }));
                    }}
                >
                    {({ values, errors, touched, isSubmitting }) => {

                        return (
                            <Form className="formValidate">
                               
                                <div className="row">
                                    <FieldArray
                                        name="category_items"

                                        render={arrayHelpers => (
                                            <ul
                                                className="collapsible popout col s12"
                                            >
                                                {
                                                    values.items.length ? (values.items.map((item, i) => {
                                                        return (
                                                            <li className="m-0 mb-3" key={"menu-item-" + i} >
                                                                {
                                                                    <React.Fragment>
                                                                        <CollapsibleHeader icon="playlist_add_check" items={values.items} item={item} title="category element" />
                                                                        <ItemField index={i} item={item} arrayHelpers={arrayHelpers} />
                                                                    </React.Fragment>
                                                                }
                                                            </li>);
                                                    })) : ''
                                                }
                                                
                                            </ul>
                                        )}
                                    />

                                   

                                    {
                                        !items.length && canCreate? (
                                            <div className="card-panel deep-orange accent-1 center">
                                                <a className="btn mb-1 waves-effect waves-light gradient-45deg-blue-grey-blue center" onClick={() => { addItemHandler() }}>
                                                    <i className="material-icons">playlist_add</i> {_trans('Add item',locosBO)}
                                                </a>
                                            </div>
                                        ) : items.length && canCreate ? (
                                            <React.Fragment>
                                                    <div className="col s2 right">
                                                        <a className="btn-floating mb-1 waves-effect waves-light gradient-45deg-blue-grey-blue right" onClick={() => { addItemHandler() }}>
                                                            <i className="material-icons">playlist_add</i>
                                                        </a>
                                                    </div>
                                                    <div className="col s12 mt-3">
                                                        <button type="submit" className="btn indigo waves-effect waves-light mr-2" disabled={isSubmitting}>
                                                            {_trans('Save',locosBO)} 
                                                        </button>
                                                        <button type="button" className="btn btn-light-pink waves-effect waves-light"
                                                            onClick={cancelHandler}
                                                        >{_trans('Cancel',locosBO)} </button>
                                                    </div>
                                            </React.Fragment>
                                        ) :undefined
                                    }
                                  
                                </div>
                            </Form>
                        )

                    }}
                </Formik>

            </div>
            )
    )
}


function CollapsibleHeader({ icon, title, item, items }) {
    const dispatch = useDispatch();
    const { categoryId } = useSelector(state => state.categorySelected);

    const removeItem = (item) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Supprimer'
        }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteCategoryItem(item.id_cat_item, (resp) => {
                    if (!resp.error) {
                        Swal.fire(
                            'Supprimer',
                            'Category elements bien supprimer.',
                            'success'
                        )
                        dispatch(listCategoryItems(categoryId, (items) => {
                            dispatch(setCategoryItems(items))
                        }));
                    } else {
                        toast.error(resp.message);
                        window.location.reload();
                    }
                }));
            }
        })
      
    }
    return (
        <div className="collapsible-header" tabIndex="0">
            <span><i className="material-icons handle">{icon}</i>{title} </span>
            <a className="btn-floating delete-item  grey lighten-1" onClick={() => {
                removeItem(item)
            }}>
                <span className="material-icons">delete_forever</span>
            </a>
        </div>
    )
}

import React, { useEffect, useState } from 'react'
import { Form, Field, Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch ,useSelector} from 'react-redux';
import Errors from '../../../validations/Errors'
import { login } from '../../../actions/authActions'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton';

const CreateUsersSchema = Yup.object().shape({
    users_email: Yup.string().email().required(Errors.required({ name: "l'email" })),
    users_password: Yup.string().min(8, Errors.min({ name: "le mot de passe", number: "8" })).required(Errors.required({ name: "le mot de passe" })),
})


export default function LoginForm() {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginSuccess, setLoginSuccess] = useState(false);
    const { loading, user, success } = useSelector(state => state.auth);
    const [loginFail, setLoginFail] = useState({
        state: false,
        message: null
    });
    useEffect(() => {

        if (loginSuccess) {
            setLoginSuccess(false);
            // toast.success('Connexion réussie');
        }
        if (loginFail.state) {
            toast.error(loginFail.message);
            setLoginFail({
                state: false,
                message: null
            });
        }

    }, [loginFail, loginSuccess]);
    
    const handleSubmit = (values,cb) => {
        dispatch(login(values, (response) => {
            if (response.error) {
                cb(false);
                setLoginFail({
                    state: true,
                    message: response.message
                })
            }else{
                cb(true);
                setLoginSuccess(true);
                window.location.href = "/";
            }
        }));
    }

    return (
        <Formik
            initialValues={{
                users_email: email ? email : '',
                users_password: password ? password : '',
                remember_me: false
            }}
            validationSchema={CreateUsersSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
                await handleSubmit(values,(success)=>{
                    if(success) {
                        resetForm({ values: '' });
                    }
                });
            }}
        >
            {({ values, errors, touched, isSubmitting }) => (

                loading  ? (
                    <div className="card-panel no-shadow">
                        <div className="card-content white-text center">
                            <p> Connecting... </p>
                        </div>
                    </div>
                ) : success ? (
                    <div className="card-panel no-shadow">
                        <div className="card-content white-text center">
                            <span className="card-title"><i className="material-icons dp48">done</i></span>
                            <p> Loged In ... </p>
                        </div>
                    </div>
                ) :(
                    <Form className="login-form">
                        <div className="row margin">
                            <div className="input-field col s12">
                                <i className="material-icons prefix pt-2">person_outline</i>
                                <Field id="users_email" name="users_email" type="email" className={errors.users_email ? "error validate" : "validate"} />
                                <label htmlFor="users_email" className={email !== '' ? 'active' : ''}>Email</label>
                                {errors.users_email && touched.users_email ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.users_email}</div></small>
                                ) : null}
                            </div>
                        </div>
                        <div className="row margin">
                            <div className="input-field col s12">
                                <i className="material-icons prefix pt-2">lock_outline</i>
                                <Field id="users_password" name="users_password" type="password" className={errors.users_password ? "error validate" : "validate"} />
                                <label htmlFor="users_password" className={password !== '' ? 'active' : ''}>Mot de passe</label>
                                {errors.users_password && touched.users_password ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.users_password}</div></small>
                                ) : null}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m12 l12 ml-2 mt-1">
                                <p>
                                    <label>
                                        <Field id="remember_me" name="remember_me" type="checkbox" />
                                        <span>Remember Me</span>
                                    </label>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12">
                                <button type="submit" className="btn border-round col s12" disabled={isSubmitting}>Login</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s6 m6 l6">
                                <p className="margin left-align medium-small">
                                    <Link to="/forgot-password">Forgot password ?</Link>
                                </p>
                            </div>
                        </div>
                    </Form>
                )
            )}
        </Formik>
    )
}

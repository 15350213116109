import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { listPostType, restorePostType, deletePostType, selectedPostType, togglePostTypeSidebar, destroyPostType} from '../../actions/postTypeActions';
import { useHistory } from "react-router-dom"
import Swal from 'sweetalert2';
import useHasPermission from '../../hooks/useHasPermission'

import { _trans } from '../../actions/locoBOActions';

export default function PostsTypeDatatable({props}) {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    let history = useHistory();
    const dispatch = useDispatch();
    
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [filtredData, setFiltredData] = useState([]);
    const postTypeList = useSelector(state => state.postTypeList);
    const postTypeTrashedList = useSelector(state => state.postTypeTrashedList);
    const { postType,
        loading,
        error } = typeof props.match.params.trush === "undefined" ? postTypeList : postTypeTrashedList;
    
   
    const canUpdate = useHasPermission("/post-type", "update");
    const canDelete = useHasPermission("/post-type", "delete");
    const postTypeDelete = useSelector(state => state.postTypeDelete);
    const { loading: loadingDelete,
            success: successDelete,
            error: errorDelete } = postTypeDelete;

    const postTypeSave = useSelector(state => state.postTypeSave);
    const { loading : loadingSave, 
            success: successSave, 
            error: errorSave} = postTypeSave;


    const columns = [
        {
            name: _trans('Name',locosBO),
            selector: 'post_type_title',
            sortable: true,
        },
        {
            name: 'Slug',
            selector: 'post_type_slug',
            sortable: true,
        },
        {
            cell: (postType) => !postType.deleted_at && canUpdate ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { editPostTypeHandler(postType); }}>
                <i className="material-icons">edit</i>
            </button> : postType.deleted_at && canDelete ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { destroyPostTypeHandler(postType); }}>
                    <i className="material-icons" style={{ color: "#d32f2f" }}>delete_forever</i>
                </button> :undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: ( postType ) => 
                !postType.deleted_at && canDelete? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { deletePostTypeHandler(postType); }}>
                    <i className="material-icons">delete</i>
                </button> : postType.deleted_at && canDelete ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { restorePostTypeHandler(postType); }}>
                <i className="material-icons">restore</i>
            </button> :undefined
                ,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        }
    ];
    
    useEffect(() => {

        setFiltredData(postType);

        if(deleteSuccess){
            setDeleteSuccess(false);
            dispatch(listPostType());
        }

    }, [successSave, deleteSuccess, postType]);

    const editPostTypeHandler = ( postTypeData ) => {
        dispatch(selectedPostType(postTypeData));
        dispatch(togglePostTypeSidebar(true));
    }

    const destroyPostTypeHandler = (postType) => {
        Swal.fire({
            title: _trans('Are you sure?',locosBO),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            cancelButtonColor: '#43a047 ',
            cancelButtonText: _trans('Cancel',locosBO),
            confirmButtonText: _trans('Delete',locosBO)
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(destroyPostType(postType, (response) => {
                    history.push('/post-type')
                    dispatch(listPostType());
                    Swal.fire(
                        _trans('Delete',locosBO),
                        _trans('Post type successfully deleted',locosBO),
                        'success'
                    )
                }));

            }
        })

    }

    const restorePostTypeHandler = (postType) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#43a047',
            cancelButtonColor: '#424242 ',
            cancelButtonText: _trans('Cancel',locosBO),
            confirmButtonText: _trans('Restore',locosBO)
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(restorePostType(postType, (response) => {
                    history.push('/post-type')
                    dispatch(listPostType());
                    Swal.fire(
                        _trans('Restore',locosBO),
                        _trans('Post type successfully Restored',locosBO),
                        'success'
                    )
                }));

            }
        })

    }
    const deletePostTypeHandler = ( postTypeData ) => {
        Swal.fire({
            title: 'Êtes-vous sûr?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: _trans('Cancel',locosBO),
            confirmButtonText: _trans('Delete',locosBO)
          }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deletePostType(postTypeData,(response)=>{
                    setDeleteSuccess(true);
                    Swal.fire(
                        _trans('Delete',locosBO),
                        _trans('Post type successfully deleted',locosBO),
                        'success'
                      )
                }));
              
            }
          })
    }

    const dataFiltre = (e) =>{
        let textFiltre = e.target.value;
        let data = postType.filter(item => item.post_type_title && item.post_type_title.toLowerCase().includes(textFiltre.toLowerCase()));
        setFiltredData(data);
    }
    
    return <React.Fragment>
        {loading || loadingDelete && <div></div>} 
        {error && <div>{error}</div>} 
        {errorDelete && <div>{errorDelete}</div>}   
            <div className="content-area content-right">
                <div className="app-wrapper">
                    <div className="datatable-search">
                        <i className="material-icons mr-2 search-icon">search</i>
                        <input type="text" placeholder="Search" className="app-filter" id="global_filter" onChange={dataFiltre} />
                    </div>
                    <div id="button-trigger" className="card card card-default scrollspy border-radius-6 fixed-width">
                    <div className="p-0 users-table">
                        <DataTable
                            columns={columns}
                            data={filtredData}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            pagination={true}
                            paginationComponentOptions={{noRowsPerPage: true}}
                            highlightOnHover={true}
                            noHeader={true}
                            striped={true}
                            theme="solarized"
                        />
                    </div>
                    </div>
                </div>
            </div>      
        </React.Fragment>;
}


import { useSelector } from 'react-redux'

export default function useHasPermission(path,permission){

    const { loading: userAuthPermissionsLoading, userAuthPermissions } = useSelector(state => state.userAuthPermissions);
    const { sideBarItems } = useSelector(state => state.sideBarItems);

    const currentItem = sideBarItems?.find((item) => {
        return item.to === path
    })
    const havePermission = userAuthPermissions?.find((item) => {
        return item?.key === currentItem?.key || item?.key === "ALL" && item[permission]
    })
    return havePermission ? true : false;

}
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { pathListner } from '../../actions/pathActions';
import { listPostTemplates, savePostTemplates, selectedPostTemplate } from '../../actions/postTemplatesActions';
import { ReactSortable } from "react-sortablejs";

import { _trans } from '../../actions/locoBOActions';

export default function PostsTemplatesForm() {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO)

    const dispatch = useDispatch();

    const history = useHistory();

    const [idPostTemplates, setidPostTemplates] = useState('');
    const [postTemplatesTitle, setPostTemplatesTitle] = useState('');
    const [fields, setFields] = useState([]);

    const postTemplatesSelected = useSelector(state => state.selectedPostTemplate);

    
    useEffect(() => {

        dispatch(pathListner('/post-templates'));

        dispatch(listPostTemplates());

        if(postTemplatesSelected.postTemplates){
            setidPostTemplates(postTemplatesSelected.postTemplates.id);
            setPostTemplatesTitle(postTemplatesSelected.postTemplates.postTemplates_name);
            setFields( JSON.parse( postTemplatesSelected.postTemplates.post_fields_fields ) );
        }else{
            setidPostTemplates('');
            setPostTemplatesTitle('');
            setFields([]);
        }

        window.initPlugins();
        
    }, [postTemplatesSelected]);

    const onChangePostTemplatesName = (e) =>{
        let postsTemplateName = e.target.value;
        setPostTemplatesTitle(postsTemplateName);
    }

    // dec2hex :: Integer -> String
    // i.e. 0-255 -> '00'-'ff
    const dec2hex = (dec) =>{
        return dec.toString(16).padStart(2, "0")
    }
      
    // generateId :: Integer -> String
    const generateId = (len) =>{
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    return Array.from(arr, dec2hex).join('')
    }

    const handleChangeInput = (i, event) => {
        const values = [...fields];
        const { name, value } = event.target;
        values[i][name] = value;

        if( name === 'titre_input' || name === 'name_input'){
            let slug = value.toLowerCase()
                            .replace(/ /g,'-')
                            .replace(/[^\w-]+/g,'');
            values[i]['name_input'] = slug;
        }

        if(name === 'type_input'){
            
            if(value === 'Select'){
                values[i].select_option = '';
            }else if(value === 'CheckBox'){
                values[i].checkbox_option = '';
            }else if(value === 'Radio'){
                values[i].radio_option = '';
            }else if(value === 'Repeteur'){
                values[i].fields = [];
            }
            else{
                delete values[i].select_option;
                delete values[i].checkbox_option;
                delete values[i].radio_option;
                delete values[i].fields;
            }
            
        }


        setFields(values);
    }

    const handleAddInput = () => {
        const values = [...fields];
        values.push({
            id: generateId(20),
            titre_input: '',
            name_input: '',
            type_input: 'Text',
        });
        setFields(values);
        window.initPlugins();
    }

    const handleRemoveInput = (i) => {
        const values = [...fields];
        values.splice(i, 1);
        setFields(values);
    }

    const handleAddRepeteurInput = (i) => {
        const values = [...fields];
        values[i].fields.push({
            id: generateId(20),
            titre_input: '',
            name_input: '',
            type_input: 'Text',
        });
        setFields(values);
        window.initPlugins();
    }

    const handleRemoveRepeteurInput = (i, j) => {
        const values = [...fields];
        values[i].fields.splice(j, 1);
        setFields(values);
    }

    const handleChangeRepeteurInput = (i, j, event) => {
        const values = [...fields];
        const { name, value } = event.target;
        values[i].fields[j][name] = value;

        if( name === 'titre_input' || name === 'name_input'){
            let slug = value.toLowerCase()
                            .replace(/ /g,'-')
                            .replace(/[^\w-]+/g,'');
            values[i].fields[j]['name_input'] = slug;
        }

        if(name === 'type_input'){
            
            if(value === 'Select'){
                values[i].fields[j].select_option = '';
            }else if(value === 'CheckBox'){
                values[i].fields[j].checkbox_option = '';
            }else if(value === 'Radio'){
                values[i].fields[j].radio_option = '';
            }
            else{
                delete values[i].fields[j].select_option;
                delete values[i].fields[j].checkbox_option;
            }
            
        }

        setFields(values);
     }

    const handleSubmit = () =>{

        let values = {};
        values.id = idPostTemplates;
        values.postTemplatesTitle = postTemplatesTitle;
        values.post_fields = fields;

        dispatch(savePostTemplates(values ,(response)=>{
            history.push('/post-templates');
        }));
    }

    return <React.Fragment>

<div className="row">
    <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
    <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
        <div className="container">
            <div className="row">
                <div className="col s10 m6 l6">
                    <h5 className="breadcrumbs-title mt-0 mb-0">
                        <span>{postTemplatesSelected.postTemplates ? _trans('Edit posts templates',locosBO) : _trans('New posts templates',locosBO) }</span>
                    </h5>
                </div>
            </div>
        </div>
    </div>
    <div className="col s12">
        <div className="container">

            <div style={{ bottom: 'initial', right: '90px', top: '5px', padding: '0'  }} className="fixed-action-btn direction-top">
                <button onClick={handleSubmit} className="btn-large radient-shadow" >
                    {/* <i className="material-icons">add_box</i> */}
                    {_trans('Save',locosBO)}
                </button>
            </div>

            <div className="card">
                <div className="card-content row">
                    <div className="input-field col s12">
                        <input id="posts_type_name" type="text" className="validate" onChange={onChangePostTemplatesName} value={postTemplatesTitle} />
                        <label htmlFor="posts_type_name" className={postTemplatesTitle !== '' ? 'active' : ''}>{_trans('posts templates Title',locosBO)}</label>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col s12">
                    <div id="input-fields" className="card">

                        <div className="card-content post-template-content">
                            <div className="row">
                                <div className="col s12">
                                <ReactSortable
                                    handle='.handle'
                                    list={fields} setList={setFields}
                                >
                                        {fields.map((field, idx) =>{
                                            return (
                                            <div key={`${field}-${idx}`} className="row card card-content" style={field.type_input === "Repeteur"? ( { background: 'rgb(245 245 245 / 69%)' } ) : null}>
                                                <span className="post-template-handle"><i className="material-icons handle">open_with</i></span>
                                                <button className="btn waves-effect waves-light left post-template-btn-delete" onClick={() => handleRemoveInput(idx)} type="button" name="action">
                                                    <i className="material-icons left">delete</i>
                                                </button>
                                                <div className="input-field col s4">
                                                    <input 
                                                        id={`titre_input_${idx}`}
                                                        name="titre_input"
                                                        type="text"
                                                        value={field.titre_input}
                                                        onChange={e => handleChangeInput(idx, e)}
                                                        className="validate" />
                                                    <label htmlFor={`titre_input_${idx}`} className={field.titre_input !== '' ? 'active' : ''}>{_trans('Field Title',locosBO)}</label>
                                                </div>
                                                <div className="input-field col s4">
                                                    <input 
                                                        id={`name_input_${idx}`}
                                                        name="name_input"
                                                        type="text" 
                                                        value={field.name_input}
                                                        onChange={e => handleChangeInput(idx, e)}
                                                        className="validate" />
                                                    <label htmlFor={`name_input_${idx}`} className={field.name_input !== '' ? 'active' : ''}>{_trans('Field Name',locosBO)}</label>
                                                </div>
                                                <div className="input-field col s3">
                                                    <label htmlFor={`type_input_${idx}`} className="active">{_trans('Field type',locosBO)}</label>
                                                    <select 
                                                        id={`type_input_${idx}`}
                                                        name="type_input"
                                                        onChange={e => handleChangeInput(idx, e)}
                                                        value={field.type_input}
                                                        className="validate">
                                                        <option value="Text">Text</option>
                                                        <option value="Textarea">Textarea</option>
                                                        <option value="Wysiwyg">Editeur Wysiwyg</option>
                                                        <option value="Select">Select</option>
                                                        <option value="CheckBox">CheckBox</option>
                                                        <option value="Radio">Radio</option>
                                                        <option value="Image">Image</option>
                                                        <option value="Repeteur">Repeteur</option>
                                                        <option value="ImageGallery">Galerie Image</option>
                                                    </select>

                                                    {field.type_input === "Select"? (
                                                        <textarea 
                                                        placeholder="options"
                                                        className="post_type_select_option"
                                                        name="select_option"
                                                        value={field.select_option} 
                                                        onChange={e => handleChangeInput(idx, e)}></textarea>
                                                    ): field.type_input === "CheckBox"? (
                                                        <textarea 
                                                        placeholder="options"
                                                        className="post_type_select_option"
                                                        name="checkbox_option"
                                                        value={field.checkbox_option} 
                                                        onChange={e => handleChangeInput(idx, e)}></textarea>
                                                    ): field.type_input === "Radio"? (
                                                        <textarea 
                                                        placeholder="options"
                                                        className="post_type_select_option"
                                                        name="radio_option"
                                                        value={field.radio_option} 
                                                        onChange={e => handleChangeInput(idx, e)}></textarea>
                                                    ): null}
                                                </div>
                                                
                                                {field.type_input === "Repeteur"? (
                                                    <div className="col s12">
                                                        <div className="">
                                                        <ReactSortable
                                                            handle='.handle-repeteur'
                                                            list={field.fields} setList={(data)=>{
                                                                let index = fields.findIndex((itmInner) => itmInner.id === field.id);
                                                                let fieldItems = [...fields];
                                                                fieldItems[index].fields = data;
                                                                setFields(fieldItems);
                                                                window.initPlugins();
                                                            }}
                                                        >
                                                            {field.fields.map((fieldRepeteur, idxRepeteur) =>{
                                                                return(
                                                                    <div key={`${field}-${idx}-${idxRepeteur}`} className="row card card-content">
                                                                        <span className="post-template-handle-repeteur"><i className="material-icons handle-repeteur">open_with</i></span>
                                                                        <button className="btn waves-effect waves-light left post-template-btn-delete" onClick={() => handleRemoveRepeteurInput(idx ,idxRepeteur)} type="button" name="action">
                                                                            <i className="material-icons left">delete</i>
                                                                        </button>
                                                                        <div className="input-field col s4">
                                                                            <input 
                                                                                id={`titre_input_${idx}_${idxRepeteur}`}
                                                                                name="titre_input"
                                                                                type="text"
                                                                                value={fieldRepeteur.titre_input}
                                                                                onChange={e => handleChangeRepeteurInput(idx ,idxRepeteur, e)}
                                                                                className="validate" />
                                                                            <label htmlFor={`titre_input_${idx}_${idxRepeteur}`} className={fieldRepeteur.titre_input !== '' ? 'active' : ''}>{_trans('Field Title',locosBO)}</label>
                                                                        </div>
                                                                        <div className="input-field col s4">
                                                                            <input 
                                                                                id={`name_input_${idx}_${idxRepeteur}`}
                                                                                name="name_input"
                                                                                type="text" 
                                                                                value={fieldRepeteur.name_input}
                                                                                onChange={e => handleChangeRepeteurInput(idx ,idxRepeteur, e)}
                                                                                className="validate" />
                                                                            <label htmlFor={`name_input_${idx}_${idxRepeteur}`} className={fieldRepeteur.name_input !== '' ? 'active' : ''}>{_trans('Field Name',locosBO)}</label>
                                                                        </div>
                                                                        <div className="input-field col s3">
                                                                            <label htmlFor={`type_input_${idx}_${idxRepeteur}`} className="active">{_trans('Field type',locosBO)}</label>
                                                                            <select 
                                                                                id={`type_input_${idx}_${idxRepeteur}`}
                                                                                name="type_input"
                                                                                onChange={e => handleChangeRepeteurInput(idx ,idxRepeteur, e)}
                                                                                value={fieldRepeteur.type_input}
                                                                                className="validate">
                                                                                <option value="Text">Text</option>
                                                                                <option value="Textarea">Textarea</option>
                                                                                <option value="Wysiwyg">Editeur Wysiwyg</option>
                                                                                <option value="Select">Select</option>
                                                                                <option value="CheckBox">CheckBox</option>
                                                                                <option value="Radio">Radio</option>
                                                                                <option value="Image">Image</option>
                                                                                <option value="ImageGallery">Galerie Image</option>
                                                                            </select>

                                                                            {fieldRepeteur.type_input === "Select"? (
                                                                                <textarea 
                                                                                placeholder="options"
                                                                                className="post_type_select_option"
                                                                                name="select_option"
                                                                                value={fieldRepeteur.select_option} 
                                                                                onChange={e => handleChangeRepeteurInput(idx ,idxRepeteur, e)}></textarea>
                                                                            ): fieldRepeteur.type_input === "CheckBox"? (
                                                                                <textarea 
                                                                                placeholder="options"
                                                                                className="post_type_select_option"
                                                                                name="checkbox_option"
                                                                                value={fieldRepeteur.checkbox_option} 
                                                                                onChange={e => handleChangeRepeteurInput(idx ,idxRepeteur, e)}></textarea>
                                                                            ): fieldRepeteur.type_input === "Radio"? (
                                                                                <textarea 
                                                                                placeholder="options"
                                                                                className="post_type_select_option"
                                                                                name="radio_option"
                                                                                value={fieldRepeteur.radio_option} 
                                                                                onChange={e => handleChangeRepeteurInput(idx ,idxRepeteur, e)}></textarea>
                                                                            ): null}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }) }
                                                        </ReactSortable>
                                                        </div>
                                                        <div className="row">
                                                            <button type="button" className="btn cyan waves-effect waves-light right" onClick={() => handleAddRepeteurInput(idx)} name="action">{_trans('Add field',locosBO)}
                                                                <i className="material-icons left">add</i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : null}
                                    
                                            </div>
                                            )
                                        } )}
                                </ReactSortable>   
                                </div>
                            </div>
                        </div>

                        <div className="card-footer">
                            <div className="row">
                                <button type="button" className="btn cyan waves-effect waves-light right" onClick={() => handleAddInput()} name="action">{_trans('Add field',locosBO)}
                                    <i className="material-icons left">add</i>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <div className="content-overlay"></div>
    </div>
</div>
    </React.Fragment>;
}

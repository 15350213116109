import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { _trans } from '../../actions/locoBOActions';

export default function UsersCount( props ) {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const usersList = useSelector(state => state.usersList);
    const { users, 
            loading,
            error } = usersList;

    useEffect(() => {
        
    }, [users]);     

    return (
        <React.Fragment>
            {loading ? <div></div> :  
                error ? <div>{error}</div> : 
                
                <p className="m-0 text-muted">{users.length} {_trans('Users',locosBO)}</p>
            }
        </React.Fragment>
    );
}
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { listLanguages, deleteLanguage, selectedLanguage, toggleLanguagesSidebar, restoreLanguage, destroyLanguage} from '../../actions/languagesActions';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";
import useHasPermission from '../../hooks/useHasPermission'

import { _trans } from '../../actions/locoBOActions';

export default function LanguagesDatatable() {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const dispatch = useDispatch();
    let history = useHistory();
    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [filtredData, setFiltredData] = useState([]);

    const canUpdate = useHasPermission("/languages", "update");
    const canDelete = useHasPermission("/languages", "delete");

    const languagesList = useSelector(state => state.languagesList);
    const { languages, 
            loading,
            error } = languagesList;

    const languageDelete = useSelector(state => state.languagesDelete);
    const { loading: loadingDelete,
            success: successDelete,
            error: errorDelete } = languageDelete;

    const languagesSave = useSelector(state => state.languagesSave);
    const { loading : loadingSave, 
            success: successSave, 
            error: errorSave} = languagesSave;


    const columns = [
        {
            name: _trans('Flag',locosBO),
            cell: ( Language ) => <img className="flag" src={ Language.langs_flag ? '/'+Language.langs_flag : "/app-assets/images/Language/default-avatar.png" } alt="" />,
        },
        {
            name: _trans('Name',locosBO),
            selector: 'langs_title',
            sortable: true,
        },
        {
            name: _trans('Code',locosBO),
            selector: 'langs_code',
            sortable: true,
        },
        {
            name: _trans('By defaut',locosBO),
            selector: 'langs_default',
            sortable: true,
            cell: (Language) => Language.langs_default ? <div className="card-alert card green">
                <div className="card-content white-text">
                    <p>{_trans('Yes',locosBO)}</p>
                </div>
            </div> : <div className="card-alert card red">
                    <div className="card-content white-text">
                        <p>{_trans('No',locosBO)}</p>
                    </div>
                </div>,
        },
        {
            name: _trans('Direction',locosBO),
            selector: 'langs_direction',
            sortable: true,
        },
        {
            cell: (Language) => !Language.deleted_at && canUpdate  ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { editLanguageHandler(Language); }}>
                <i className="material-icons">edit</i>
            </button> : Language.deleted_at && canDelete ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { destroyLanguageHandler(Language); }}>
                    <i className="material-icons" style={{ color: "#d32f2f"}}>delete_forever</i>
                </button> : undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: (Language) => !Language.deleted_at && canDelete ?  <button className="btn-flat p-0" style={{width: "100%", height: "100%"}} onClick={ (e) => { deleteLanguageHandler(Language); } }>
                <i className="material-icons">delete</i>
            </button> : Language.deleted_at && canDelete  ? <button className="btn-flat p-0" style={{ width: "100%", height: "100%" }} onClick={(e) => { restoreLanguageHandler(Language); }}>
                    <i className="material-icons">restore</i>
                </button> : undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        }
    ];
    
    useEffect(() => {

        setFiltredData(languages);

        if(deleteSuccess){
            setDeleteSuccess(false);
            dispatch(listLanguages());
        }

    }, [successSave, deleteSuccess, languages]);

    const editLanguageHandler = ( LanguageData ) => {
        dispatch(selectedLanguage(LanguageData));
        dispatch(toggleLanguagesSidebar(true));
    }
    const restoreLanguageHandler = ( lang ) => {
        Swal.fire({
            title: _trans('Are you sure?',locosBO),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#43a047',
            cancelButtonColor: '#424242 ',
            cancelButtonText: _trans('Cancel',locosBO),
            confirmButtonText: _trans('Restore',locosBO)
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(restoreLanguage(lang, (response) => {
                    history.push('/languages')
                    dispatch(listLanguages());
                    Swal.fire(
                        _trans('Restore',locosBO),
                        _trans('Language successfully Restored',locosBO),
                        'success'
                    )
                }));

            }
        })
       
    }
    const destroyLanguageHandler = ( lang ) => {
        Swal.fire({
            title: _trans('Are you sure?',locosBO),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d32f2f',
            cancelButtonColor: '#43a047 ',
            cancelButtonText: _trans('Cancel',locosBO),
            confirmButtonText: _trans('Delete',locosBO)
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(destroyLanguage(lang, (response) => {
                    history.push('/languages')
                    dispatch(listLanguages());
                    Swal.fire(
                        _trans('Delete',locosBO),
                        _trans('Language successfully deleted',locosBO),
                        'success'
                    )
                }));

            }
        })
       
    }

    const deleteLanguageHandler = ( LanguageData ) => {
        Swal.fire({
            title: _trans('Are you sure?',locosBO),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Supprimer'
          }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deleteLanguage(LanguageData,(response)=>{
                    setDeleteSuccess(true);
                    Swal.fire(
                        _trans('Delete',locosBO),
                        _trans('Language successfully deleted',locosBO),
                        'success'
                      )
                }));
              
            }
          })
    }

    const dataFiltre = (e) =>{
        let textFiltre = e.target.value;
        let data = languages.filter(item => item.langs_title && item.langs_title.toLowerCase().includes(textFiltre.toLowerCase()));
        setFiltredData(data);
    }
    
    return <React.Fragment>
        {loading || loadingDelete && <div></div>} 
        {error && <div>{error}</div>} 
        {errorDelete && <div>{errorDelete}</div>}   
            <div className="content-area content-right">
                <div className="app-wrapper">
                    <div className="datatable-search">
                        <i className="material-icons mr-2 search-icon">search</i>
                        <input type="text" placeholder="Search" className="app-filter" id="global_filter" onChange={dataFiltre} />
                    </div>
                    <div id="button-trigger" className="card card card-default scrollspy border-radius-6 fixed-width">
                    <div className="p-0 Languages-table">
                        <DataTable
                            columns={columns}
                            data={filtredData}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            pagination={true}
                            paginationComponentOptions={{noRowsPerPage: true}}
                            highlightOnHover={true}
                            noHeader={true}
                            striped={true}
                            theme="solarized"
                        />
                    </div>
                    </div>
                </div>
            </div>      
        </React.Fragment>;
}


import React from 'react'
import { useSelector, useDispatch } from 'react-redux'


export default function NavbarList() {

    const { loading, user } = useSelector(state => state.login);

    return (
        <ul className="navbar-list right">
            {/* <li className="dropdown-language"><a className="waves-effect waves-block waves-light translation-button" href="#" data-target="translation-dropdown"><span className="flag-icon flag-icon-gb"></span></a></li> */}
            {/* <li className="hide-on-large-only search-input-wrapper"><a className="waves-effect waves-block waves-light search-button" href="#"><i className="material-icons">search</i></a></li> */}
            {/* <li><a className="waves-effect waves-block waves-light notification-button" href="#" data-target="notifications-dropdown"><i className="material-icons">notifications_none<small className="notification-badge">5</small></i></a></li> */}
            <li><a className="waves-effect waves-block waves-light profile-button" href="#" data-target="profile-dropdown"><span className="avatar-status avatar-online"><img src={user.token.authUser.users_image ? '/' +user.token.authUser.users_image : "/app-assets/images/user/default-avatar.png"} alt="avatar" /><i></i></span></a></li>
        </ul>
    )
}

import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { _trans } from '../../actions/locoBOActions';

export default function PermissionsCount() {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const permissionsList = useSelector(state => state.permissionsList);
    const { permissions,
        loading,
        error } = permissionsList;

    useEffect(() => {

    }, [permissions]);

    return (
        <React.Fragment>
            {loading ? <div></div> :
                error ? <div>{error}</div> :

                    <p className="m-0 text-muted">{permissions.length} {_trans('Permissions',locosBO)}</p>
            }
        </React.Fragment>
    );
}
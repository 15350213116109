export const LEADS_LIST_REQUEST = 'LEADS_LIST_REQUEST';
export const LEADS_LIST_SUCCESS = 'LEADS_LIST_SUCCESS'; 
export const LEADS_LIST_FAIL = 'LEADS_LIST_FAIL';
export const LEADS_SAVE_REQUEST = 'LEADS_SAVE_REQUEST';
export const LEADS_SAVE_SUCCESS = 'LEADS_SAVE_SUCCESS';
export const LEADS_SAVE_FAIL = 'LEADS_SAVE_FAIL'; 
export const LEADS_DELETE_REQUEST = 'LEADS_DELETE_REQUEST';
export const LEADS_DELETE_SUCCESS = 'LEADS_DELETE_SUCCESS';
export const LEADS_DELETE_FAIL = 'LEADS_DELETE_FAIL';
export const SELECTED_LEAD = 'SELECTED_LEAD';
export const LEADS_SIDEBAR_IS_TOGGLED = 'LEADS_SIDEBAR_IS_TOGGLED';
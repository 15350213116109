import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, Formik } from 'formik';
import * as Yup from 'yup';
import Errors from '../../validations/Errors';
import { toast } from 'react-toastify';
import { listUsers, saveUser, selectedUser, toggleUsersSidebar } from '../../actions/usersActions';
import { toggleFilesManagerModal, selectedFile } from '../../actions/fileManagerActions';
import Select from 'react-select'
import { customStyles } from '../../styles/reactSelect'
import { userAuthPermissions } from '../../actions/usersActions';

import { _trans } from '../../actions/locoBOActions';

export default function UsersFormSideBar(props) {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const CreateUsersSchema = Yup.object().shape({
        users_name: Yup.string().required( Errors.required({ name: _trans('The name',locosBO) }) ),
        users_email: Yup.string().email().required( Errors.required({ name: _trans('The Email',locosBO) }) ),
        users_password: Yup.string().min( 8, Errors.min({ name: _trans('The password',locosBO), number: "8" }) ).required( Errors.required({ name: _trans('The password',locosBO) }) ),
        id_role: Yup.string().required( Errors.required({ name: _trans('The role',locosBO) }) )
    })
    const UpdateUsersSchema = Yup.object().shape({
        users_name: Yup.string().required( Errors.required({ name: _trans('The name',locosBO) }) ),
        users_email: Yup.string().email().required( Errors.required({ name: _trans('The Email',locosBO) }) ),
        id_role: Yup.string().required( Errors.required({ name: _trans('The name',locosBO) }) )
    })

    const dispatch = useDispatch();

    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveFail, setSaveFail] = useState({
        state : false,
        message : null
    });

    const [id, setId] = useState(null);
    const [image, setImage] = useState("/app-assets/images/user/default-avatar.png");
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [id_role, setRole] = useState('');

    const usersToggleSidebar = useSelector(state => state.toggleUsersSidebar);

    const userSelected = useSelector(state => state.selectedUser);

    const usersSave = useSelector(state => state.usersSave);
    const { loading : loadingSave, 
            success: successSave, 
            error: errorSave} = usersSave;

    const rolesList = useSelector(state => state.rolesList);
    const { roles, 
            loading: loadingRoles, 
            error: errorRoles } = rolesList;

    const fileSelected = useSelector(state => state.selectedFile);

    const roleOptions = [];
    if (roles) {
        roles.filter(role => {
            let option = {
                label: role.roles_name,
                value: role.id
            }
            roleOptions.push(option);
        })
    }

    
    useEffect(() => {

       if(userSelected.user){
            setId(userSelected.user.id);
            setImage(userSelected.user.users_image);
            setName(userSelected.user.users_name);
            setEmail(userSelected.user.users_email);
            setPassword(userSelected.user.users_password);
            setRole(userSelected.user.id_role);
        }else{
            setId(null);
            setName('');
            setEmail('');
            setPassword('');

            if(roles?.length > 0){
                setRole(roles[0].id); 
            }
        }

        if(fileSelected.file){
            setImage(fileSelected.file.path);
        }
        
        if(saveSuccess){
            setSaveSuccess(false);
            dispatch(listUsers());
            toast.success( _trans('User Saved Successfully',locosBO) );
        }
        if (saveFail.state){
            toast.error(saveFail.message);
            dispatch(listUsers());
            setSaveFail({
                state: false,
                message: null
            });
        }

    }, [userSelected, saveSuccess, fileSelected, saveFail]);
    

    const handleSubmit = (values) =>{
        
        if(fileSelected.file){
            values.users_image = fileSelected.file.path;
        }else{
            values.users_image = image;
        }

        dispatch(saveUser(values ,(response)=>{
            dispatch(userAuthPermissions());
            response.error ? setSaveFail({
                state: true,
                message: response.message
            }) : setSaveSuccess(true);
        }));
    }

    const closeFormSidebar = () => {
        dispatch( selectedUser(null) );
        dispatch( toggleUsersSidebar(false) );
        dispatch( selectedFile( null ) );
        setImage(null);
    }

    const openFilesManagerModal = () =>{
        dispatch( toggleFilesManagerModal(true) );
    }

    return <React.Fragment>
        {loadingSave && <div></div> }
        {errorSave && <div>{errorSave}</div> }

            <div className={usersToggleSidebar.toggle ? "contact-compose-sidebar show" : "contact-compose-sidebar"}>
            <div className="card quill-wrapper">
            <div className="card-content pt-0">
            <div className="card-header display-flex pb-2">
                <h3 className="card-title contact-title-label">{ !id ? _trans('Create New User',locosBO) : _trans('Update User',locosBO) }</h3>
                <div className="close close-icon" onClick={closeFormSidebar}>
                    <i className="material-icons">close</i>
                </div>
            </div>
            <div className="divider"></div> 

            <Formik
                initialValues={{
                    id: id ? id : '',
                    users_name: name ? name : '',
                    users_email: email ? email : '',
                    users_password: '',
                    id_role: id_role ? id_role : '',
                }}
                validationSchema={id ? UpdateUsersSchema : CreateUsersSchema}
                enableReinitialize={true}
                onSubmit={async (values, {resetForm}) => {
                    closeFormSidebar();
                    handleSubmit(values);
                    resetForm({values: ''});
                }}
            >
            {({ values, errors, touched, isSubmitting, setFieldValue, setFieldTouched }) => (
                    <Form className="edit-contact-item mb-5 mt-5">
                        <div className="row">
                            <div className="input-field col s12 users-form-avatar-container">

                                {image ? (
                                    <img src={image} alt="users avatar" className="z-depth-4 circle users-form-avatar" height="200" width="200"></img>
                                ): null}
                                <button type="button" className="btn" onClick={openFilesManagerModal}>{_trans('image',locosBO)}</button>
                            </div>
                            <div className="input-field col s12">
                                <Field id="users_name" name="users_name" type="text" className={errors.users_name ? "error validate" : "validate"} />
                                <label htmlFor="users_name" className={name !== '' ? 'active' : ''}>{_trans('Name',locosBO)}</label>
                                {errors.users_name && touched.users_name ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.users_name}</div></small>
                                ) : null}
                            </div>
                            <div className="input-field col s12">
                                <Field id="users_email" name="users_email" type="email" className={errors.users_email ? "error validate" : "validate"} />
                                <label htmlFor="users_email" className={email !== '' ? 'active' : ''}>{_trans('Email',locosBO)}</label>
                                {errors.users_email && touched.users_email ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.users_email}</div></small>
                                ) : null}
                            </div>
                                {!id ? (
                                    <div className="input-field col s12">
                                        <Field id="users_password" name="users_password" type="password" className={errors.users_password ? "error validate" : "validate"} />
                                        <label htmlFor="users_password" className={password !== '' ? 'active' : ''}>{_trans('Password',locosBO)}</label>
                                        {errors.users_password && touched.users_password ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.users_password}</div></small>
                                        ) : null}
                                    </div>
                                ) : ""}
                         

                            <div className="input-field col s12">
                                {!loadingRoles && 
                                 <Select
                                    name="id_role"
                                    id="id_role"
                                    onBlur={() => setFieldTouched("id_role", true)}
                                    value={roleOptions.filter(opt => { return (opt.value ===  id_role  ) })}
                                    onChange={(opt, e) => {
                                        setRole(opt?.value);
                                        setFieldValue("id_role", opt?.value);
                                    }}
                                    blurInputOnSelect={true}
                                    placeholder=""
                                    isSearchable={true}
                                    isClearable={true}
                                    styles={customStyles}
                                    options={roleOptions}
                                    error={errors.id_role}
                                    touched={touched.id_role}
                                />
                                }
                                <label htmlFor="id_role" className='active'>{_trans('Role',locosBO)}</label>
                                {errors.id_role && touched.id_role ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.id_role}</div></small>
                                ) : null}
                            </div>

                        </div>

                        <div className="card-action pl-0 pr-0 right-align">
                            
                                <button type="submit" className="btn-small waves-effect waves-light add-contact" disabled={isSubmitting}>
                                    {!id? (
                                        <span>{_trans('Save',locosBO)}</span>
                                        ) : (
                                        <span>{_trans('Edit',locosBO)}</span>
                                    )}
                                </button>

                        </div>

                    </Form>
                )}

            </Formik>

            </div>
            </div>
            </div>
        </React.Fragment> ;
}
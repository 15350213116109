import React from 'react';

function Footer() {
    return (
        <React.Fragment>
            {/* <!-- BEGIN: Footer--> */}
            <footer className="page-footer footer footer-static footer-dark navbar-border navbar-shadow">
            <div className="footer-copyright">
            <div className="container"><span className="right">&copy; 2022  <a href="https://www.lugassy-digital.com/" target="_blank" rel="noreferrer">Evonativ</a> All rights reserved.</span></div>
            </div>
            </footer>
            {/* <!-- END: Footer--> */}
        </React.Fragment>
    );
}

export default Footer;


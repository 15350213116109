import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const { FILES_LIST_REQUEST, 
        FILES_LIST_SUCCESS, 
        FILES_LIST_FAIL,
        FILEMANAGER_MODAL_IS_TOGGLED,
        UPLOAD_FILES_REQUEST,
        UPLOAD_FILES_SUCCESS,
        UPLOAD_FILES_FAIL,
        UPLOAD_FILES_IN_PROGRESS,
        SELECTED_FILE,
        SELECTED_MULTI_FILES
    } = require("../constants/filesManagerConstants");

const listFiles = () => async (dispatch) => {
    try {
        dispatch({ type: FILES_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+"/api/files_manager/files",
            headers: headers
        });
        dispatch({ type: FILES_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: FILES_LIST_FAIL, payload: error.message });
    }
}

const uploadFiles  =  (formData) => async (dispatch) => {
    try {
        dispatch({ type: UPLOAD_FILES_REQUEST });
        await axios.post(apiProxy+"/api/files_manager/uploads", formData, {
            headers: {
                ...headers,
                'Content-type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                dispatch({ type: UPLOAD_FILES_IN_PROGRESS, payload: { progress: parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))} });
            }
        }).then((res) => {
            dispatch({ type: UPLOAD_FILES_SUCCESS, payload: { file: res.data} });
        });
    } catch (error) {
        dispatch({ type: UPLOAD_FILES_FAIL, payload: error.message });
    }
}


const selectedFile = (file) => async (dispatch) => {
    dispatch({ type: SELECTED_FILE, payload: file });
}

const selectedMultiFiles = (files) => async (dispatch) => {
    dispatch({ type: SELECTED_MULTI_FILES, payload: files });
}


const toggleFilesManagerModal = (toggle) => async (dispatch) => {
    dispatch({ type: FILEMANAGER_MODAL_IS_TOGGLED, payload: toggle });
}

export { listFiles, selectedFile, selectedMultiFiles, toggleFilesManagerModal, uploadFiles}
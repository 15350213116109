import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import Skeleton from 'react-loading-skeleton';

// actions 
import { findCategoryItem } from '../../actions/categoryItemsActions';
import Select from 'react-select'
import { customStyles } from '../../styles/reactSelect'

export default function ItemField(props) {

    const dispatch = useDispatch();

    let item = props.item;
    const [title, setTitle] = useState("");
    const [slug, setSlug] = useState("");
    const [lang, setLang] = useState("");
    const [idCatItem, setIdCatItem] = useState("");
    
    const { languages, loading: langsLoading, error: langsError } = useSelector(state => state.languagesList);
    const { categoryId } = useSelector(state => state.categorySelected);
    const { currentLang } = useSelector(state => state.currentLang);

    const languageOptions = []
    languages.filter(cat => {
        let option = {
            label: cat.langs_title,
            value: cat.id
        }
        languageOptions.push(option);
    })

    useEffect(() => {
        setTitle(item.cat_item_title)
        setSlug(item.cat_item_slug)
        setIdCatItem(item.id_cat_item)
        setLang(currentLang.id)
        item.id_cat_item = item.id_cat_item;
    }, [item])

    const handleChangeSlug = (e) => {
        let value = e.target.value;
        let slug = value.toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
        item.cat_item_slug = slug;
        setSlug(slug);
    }

    const handelLangChange = (id_lang, idCatItem) => {

        dispatch(findCategoryItem(id_lang, idCatItem, (item) => {
            if (item) {
                setTitle(item.cat_item_title)
                setSlug(item.cat_item_slug)
                setIdCatItem(item.id_cat_item)
                setLang(item.id_lang)
            }else{
                setTitle("")
                setSlug("")
            }
        }))

    }

    return (
        <div className="collapsible-body">
            <div className="row">
                <div className="input-field col m8 s8">
                    <Field name={`items[${props.index}].cat_item_title`} value={title} type="text" onChange={(e) => {
                        item.cat_item_title = e.target.value;
                        setTitle(e.target.value);
                        handleChangeSlug(e);
                    }} />
                    <Field name={`items[${props.index}].id_cat_item`}  value={idCatItem} type="hidden"  />
                    <label htmlFor="name" className="active">Title</label>
                </div>
                {langsLoading ? <Skeleton count={1} height={50} />  : idCatItem ? 
               
                (
                    <div className="input-field col m4 s4 item-lang">
                        <Select
                            name="id_lang"
                            id="id_lang"
                            value={languageOptions.filter(opt => { return (opt.value === lang) })}
                            onChange={(opt, e) => {
                                item.id_lang = opt?.value;
                                setLang(opt?.value);
                                handelLangChange(opt?.value, idCatItem);
                            }}
                            blurInputOnSelect={true}
                            placeholder=""
                            isSearchable={true}
                            isClearable={true}
                            styles={customStyles}
                            options={languageOptions}
                        />
                    </div>
                ) : ""
            }
            </div>
            <div className="row">
                <div className="input-field col m8 s8">
                    <Field name={`items[${props.index}].cat_item_slug`} value={slug} type="text" onChange={(e) => {
                        handleChangeSlug(e);
                    }} />
                    <label htmlFor="name" className="active">Slug</label>
                </div>
            </div>
        </div>
    )
}

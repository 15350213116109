import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { pathListner } from '../../actions/pathActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {listLeads, saveLead, deleteLead, selectedLead, toggleLeadsSidebar} from '../../actions/leadsActions';
import LeadsFormSidebar from './LeadsFormSidebar';
import LeadsFiltre from './LeadsFiltre';
import LeadsDataTable from './LeadsDatatable';
import { listPermissions } from '../../actions/PermissionsActions';
import useHasPermission from '../../hooks/useHasPermission';

export default function Leads() {

    const dispatch = useDispatch();
    const canCreate = useHasPermission("/leads", 'create');
    useEffect(() => {

        dispatch(listLeads());
        dispatch(listPermissions());
        dispatch(pathListner('/leads'));
        
    }, [])

    const addLead = () => {
        dispatch(selectedLead(null));
        dispatch(toggleLeadsSidebar(true));
    }

    return <React.Fragment>
        
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="row">
                <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
                <div className="col s12">
                    <div className="container">
                        {/* <!-- Add new lead popup --> */}
                        <div className="lead-overlay"></div>
                        {
                            canCreate ? (
                                <div style={{ bottom: '54px', right: '19px' }} className="fixed-action-btn direction-top">
                                    <a className="btn-floating btn-large primary-text gradient-shadow lead-sidebar-trigger" onClick={addLead}>
                                        <i className="material-icons">add_box</i>
                                    </a>
                                </div>
                            ) : undefined
                        }
                        {/* <!-- Add new lead popup Ends--> */}



                        <LeadsFiltre />
                        
                        <LeadsDataTable />

                        <LeadsFormSidebar />

                    </div>
                    <div className="content-overlay"></div>
                </div>
            </div>
        </React.Fragment>;
}

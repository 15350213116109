import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
export default function PostsListCount( props ) {

    const postsList = useSelector(state => state.postsList);
    const { posts, 
            loading,
            error } = postsList;

    useEffect(() => {
        
    }, [postsList]);     

    return (
        <React.Fragment>
            {loading ? <div></div> :  
                error ? <div>{error}</div> : 
                
                <p className="m-0 text-muted">{posts.length} {props.post_data ? props.post_data.post_type_title : "" }</p>
            }
        </React.Fragment>
    );
}
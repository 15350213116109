import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useSelector, useDispatch } from 'react-redux';
import { listPermissions, deletePermission, selectedPermissions, togglePermissionsSidebar} from '../../actions/PermissionsActions';
import Swal from 'sweetalert2';
import useHasPermission from '../../hooks/useHasPermission'

import { _trans } from '../../actions/locoBOActions';

export default function PermissionsDatatable() {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const dispatch = useDispatch();

    const [deleteSuccess, setDeleteSuccess] = useState(false);

    const [filtredData, setFiltredData] = useState([]);

    const permissionsList = useSelector(state => state.permissionsList);
    const { permissions, 
            loading,
            error } = permissionsList;

    const permissionDelete = useSelector(state => state.permissionsDelete);
    const { loading: loadingDelete,
            success: successDelete,
            error: errorDelete } = permissionDelete;

    const permissionsSave = useSelector(state => state.permissionsSave);
    const { loading : loadingSave, 
            success: successSave, 
            error: errorSave} = permissionsSave;
    const canUpdate = useHasPermission("/permissions", "update");
    const canDelete = useHasPermission("/permissions", "delete");
    const setBadge = (el) =>{
        const className = el ? "green" : "red";
        return <span className={"new badge m-0 " + className} data-badge-caption={el ? _trans('Yes',locosBO) : _trans('No',locosBO) }></span>
    }

    const columns = [
        {
            name: _trans('Permission',locosBO),
            selector: 'permissions_name',
            sortable: true,
        },
        {
            name: _trans('Show',locosBO),
            selector: 'show',
            sortable: true,
            cell: (permission) => setBadge(permission.show) 
            
        },
        {
            name: _trans('Create',locosBO),
            selector: 'create',
            sortable: true,
            cell: (permission) => setBadge(permission.create)

        },
        {
            name: _trans('Update',locosBO),
            selector: 'update',
            sortable: true,
            cell: (permission) => setBadge(permission.update) 

        },
        {
            name: _trans('Delete',locosBO),
            selector: 'delete',
            sortable: true,
            cell: (permission) => setBadge(permission.delete)
        },
        {
            cell: (permission) => canUpdate? <button className="btn-flat p-0" style={{width: "100%", height: "100%"}} onClick={ (e) => { editPermissionsHandler(permission); } }>
                <i className="material-icons">edit</i>
                </button> : undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        },
        {
            cell: (permission) => canDelete? <button className="btn-flat p-0" style={{width: "100%", height: "100%"}} onClick={ (e) => { deletePermissionsHandler(permission); } }>
                <i className="material-icons">delete</i>
                </button>:undefined,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '50px'
        }
    ];
    
    useEffect(() => {

        setFiltredData(permissions);

        if(deleteSuccess){
            setDeleteSuccess(false);
            dispatch(listPermissions());
        }

    }, [successSave, deleteSuccess, permissions]);

    const editPermissionsHandler = ( permissionData ) => {
        dispatch(selectedPermissions(permissionData));
        dispatch(togglePermissionsSidebar(true));
    }

    const deletePermissionsHandler = ( permissionData ) => {
        Swal.fire({
            title: _trans('Are you sure?',locosBO),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: _trans('Cancel',locosBO),
            confirmButtonText: _trans('Delete',locosBO)
          }).then((result) => {
            if (result.isConfirmed) {

                dispatch(deletePermission(permissionData,(response)=>{
                    setDeleteSuccess(true);
                    Swal.fire(
                        _trans('Delete',locosBO),
                        _trans('Permission successfully deleted',locosBO),
                        'success'
                      )
                }));
              
            }
          })
    }

    const dataFiltre = (e) =>{
        let textFiltre = e.target.value;
        let data = permissions.filter(item => item.permissions_name && item.permissions_name.toLowerCase().includes(textFiltre.toLowerCase()));
        setFiltredData(data);
    }
    
    return <React.Fragment>
        {loading || loadingDelete && <div></div>} 
        {error && <div>{error}</div>} 
        {errorDelete && <div>{errorDelete}</div>}   
            <div className="content-area content-right">
                <div className="app-wrapper">
                    <div className="datatable-search">
                        <i className="material-icons mr-2 search-icon">search</i>
                        <input type="text" placeholder="Search" className="app-filter" id="global_filter" onChange={dataFiltre} />
                    </div>
                    <div id="button-trigger" className="card card card-default scrollspy border-radius-6 fixed-width">
                    <div className="p-0 users-table">
                        <DataTable
                            columns={columns}
                            data={filtredData}
                            selectableRows={true}
                            selectableRowsHighlight={true}
                            pagination={true}
                            paginationComponentOptions={{noRowsPerPage: true}}
                            highlightOnHover={true}
                            noHeader={true}
                            striped={true}
                            theme="solarized"
                        />
                    </div>
                    </div>
                </div>
            </div>      
        </React.Fragment>;
}


import React, { useEffect } from 'react';
import {  useDispatch } from 'react-redux';
import { pathListner } from '../../actions/pathActions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { listPermissions, selectedPermissions, togglePermissionsSidebar } from '../../actions/PermissionsActions';
import PermissionsFormSidebar from './PermissionsFormSidebar';
import PermissionsFiltre from './PermissionsFiltre';
import PermissionsDatatable from './PermissionsDatatable';
import useHasPermission from '../../hooks/useHasPermission'

export default function Permissions() {

    const dispatch = useDispatch();
    const canCreate = useHasPermission("/permissions",'create');
    useEffect(() => {

        dispatch(listPermissions());
        dispatch(pathListner('/permissions'));

    }, [])

    const addPermission = () => {
        dispatch(selectedPermissions(null));
        dispatch(togglePermissionsSidebar(true));
    }

    return <React.Fragment>

        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />

        <div className="row">
            <div className="content-wrapper-before gradient-45deg-indigo-purple"></div>
            <div className="col s12">
                <div className="container">
                    {/* <!-- Add new contact popup --> */}
                    <div className="contact-overlay"></div>
                    {
                        canCreate?(
                            <div style={{ bottom: '54px', right: '19px' }} className="fixed-action-btn direction-top">
                                <a className="btn-floating btn-large primary-text gradient-shadow contact-sidebar-trigger" onClick={addPermission}>
                                    <i className="material-icons">add_box</i>
                                </a>
                            </div>
                        ):undefined
                    }
                 
                    {/* <!-- Add new contact popup Ends--> */}

                    <PermissionsFiltre />

                    <PermissionsDatatable />

                    <PermissionsFormSidebar />

                </div>
                <div className="content-overlay"></div>
            </div>
        </div>
    </React.Fragment>;
}

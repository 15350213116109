import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { _trans } from '../../actions/locoBOActions';

export default function ContactsCount( props ) {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const contactsList = useSelector(state => state.contactsList);
    const { contacts, 
            loading,
            error } = contactsList;

    useEffect(() => {
        
    }, [contacts]);     

    return (
        <React.Fragment>
            {loading ? <div></div> :  
                error ? <div>{error}</div> : 
                
                <p className="m-0 text-muted">{contacts.length} {_trans('Contacts',locosBO)}</p>
            }
        </React.Fragment>
    );
}
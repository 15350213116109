import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const { 
    USERS_LIST_REQUEST, 
    USERS_LIST_SUCCESS, 
    USERS_LIST_FAIL,
    USERS_SAVE_REQUEST,
    USERS_SAVE_SUCCESS,
    USERS_SAVE_FAIL, 
    USERS_DELETE_REQUEST,
    USERS_DELETE_SUCCESS,
    USERS_DELETE_FAIL,
    SELECTED_USER,
    USERS_SIDEBAR_IS_TOGGLED,
    USER_AUTH_PERMISSIONS_REQUEST,
    USER_AUTH_PERMISSIONS_SUCCESS,
    USER_AUTH_PERMISSIONS_FAIL
} = require("../constants/usersConstants");



const listUsers = () => async (dispatch) => {
    try {
        dispatch({ type: USERS_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+'/api/users',
            headers: headers
        });
        dispatch({ type: USERS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: USERS_LIST_FAIL, payload: error.message });
    }
    
}

const saveUser = (user, callback) => async (dispatch) => {


        if(!user.id){
            dispatch({ type: USERS_SAVE_REQUEST, payload: {user} });
            axios({
                method: 'post',
                url: apiProxy+'/api/users',
                headers: headers,
                data: user
            }).then(data=>{
                callback({
                    error: false,
                    data: data,
                    message: null
                });
                dispatch({ type: USERS_SAVE_SUCCESS, payload: data });
            }).catch(err=>{
                callback({
                    error : true,
                    data :null,
                    message: err.response.data.message
                });
                dispatch({ type: USERS_SAVE_FAIL, payload: err.response.data.message });
            });
            
        }else{
            dispatch({ type: USERS_SAVE_REQUEST, payload: {user} });
            
            axios({
                method: 'patch',
                url: apiProxy+"/api/users/" + user.id,
                headers: headers,
                data: user
            }).then(data => {
                callback({
                    error: false,
                    data: data,
                    message: null
                });
                dispatch({ type: USERS_SAVE_SUCCESS, payload: data });
            }).catch(err => {
                callback({
                    error: true,
                    data: null,
                    message: err.response.data.message
                });
                dispatch({ type: USERS_SAVE_FAIL, payload: err.response.data.message });
            });
           
        }

  
    
}

const deleteUser = (user, callback) => async (dispatch) => {
    try {
        dispatch({ type: USERS_DELETE_REQUEST });
        const { data } = await axios(
            {
                method: 'delete',
                url: apiProxy+"/api/users/" + user.id,
                headers: headers
            });
        callback(data);
        dispatch({ type: USERS_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: USERS_DELETE_FAIL, payload: error.message });
    }
    
}

const selectedUser = (user) => async (dispatch) => {
    dispatch({ type: SELECTED_USER, payload: user });
}

const toggleUsersSidebar = (toggle) => async (dispatch) => {
    dispatch({ type: USERS_SIDEBAR_IS_TOGGLED, payload: toggle });
}


const userAuthPermissions = () => async (dispatch) => {
    try {
        dispatch({ type: USER_AUTH_PERMISSIONS_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+'/api/users/auth/permissions',
            headers: headers
        });
        dispatch({ type: USER_AUTH_PERMISSIONS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: USER_AUTH_PERMISSIONS_FAIL, payload: error.message });
    }

}

export { listUsers, saveUser, deleteUser, selectedUser, toggleUsersSidebar, userAuthPermissions}
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, Formik } from 'formik';
import * as Yup from 'yup';
import Errors from '../../validations/Errors';
import { toast } from 'react-toastify';
import { listLanguages, saveLanguage, selectedLanguage, toggleLanguagesSidebar } from '../../actions/languagesActions';
import { toggleFilesManagerModal, selectedFile } from '../../actions/fileManagerActions';

import { _trans } from '../../actions/locoBOActions';

export default function LanguagesFormSideBar(props) {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO)

    const CreateLanguagesSchema = Yup.object().shape({
        langs_title: Yup.string().required( Errors.required({ name: _trans('The Title',locosBO) }) ),
        langs_code: Yup.string().min( 2, Errors.min({ name: _trans('le code',locosBO), number: "2" }) ).required( Errors.required({ name: _trans('The code',locosBO)}) ),
        langs_direction: Yup.string().required(Errors.required({ name: _trans('The direction',locosBO) }) )
    })

    const dispatch = useDispatch();

    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveFail, setSaveFail] = useState({
        state : false,
        message : null
    });

    const [id, setId] = useState('');
    const [flag, setFlag] = useState(null);
    const [title, setTitle] = useState('');
    const [code, setCode] = useState('');
    const [direction, setDirection] = useState('LTR');
    const [defaultLang, setDefaultLang] = useState(false);

    const LanguagesToggleSidebar = useSelector(state => state.toggleLanguagesSidebar);

    const Languageselected = useSelector(state => state.selectedLanguage);

    const LanguagesSave = useSelector(state => state.languagesSave);
    const { loading : loadingSave, 
            success: successSave, 
            error: errorSave} = LanguagesSave;


    const fileSelected = useSelector(state => state.selectedFile);
    
    useEffect(() => {

       if(Languageselected.language){
            setId(Languageselected.language.id);
            setFlag(Languageselected.language.langs_flag);
            setTitle(Languageselected.language.langs_title);
            setCode(Languageselected.language.langs_code);
            setDirection(Languageselected.language.langs_direction);
            setDefaultLang(Languageselected.language.langs_default);
        }else{
            setId('');
            setTitle('');
            setFlag(null);
            setCode('');
            setDirection('LTR');
            setDefaultLang(false);
        }

        if(fileSelected.file){
            setFlag(fileSelected.file.path);
        }
        
        if(saveSuccess){
            setSaveSuccess(false);
            dispatch(listLanguages());
            toast.success( _trans('Language Saved',locosBO) );
        }
        if (saveFail.state){
            toast.error(saveFail.message);
            dispatch(listLanguages());
            setSaveFail({
                state: false,
                message: null
            });
        }

    }, [Languageselected, saveSuccess, fileSelected, saveFail]);


    const closeFormSidebar = () => {
        dispatch( selectedLanguage(null) );
        dispatch( toggleLanguagesSidebar(false) );
        dispatch( selectedFile( null ) );
    }

    const openFilesManagerModal = () =>{
        dispatch( toggleFilesManagerModal(true) );
    }


    return <React.Fragment>
        {loadingSave && <div></div> }
        {errorSave && <div>{errorSave}</div> }

            <div className={LanguagesToggleSidebar.toggle ? "contact-compose-sidebar show" : "contact-compose-sidebar"}>
            <div className="card quill-wrapper">
            <div className="card-content pt-0">
            <div className="card-header display-flex pb-2">
                <h3 className="card-title contact-title-label">{id === '' ? _trans('Create New Language',locosBO) : _trans('Update Language',locosBO) }</h3>
                <div className="close close-icon" onClick={closeFormSidebar}>
                    <i className="material-icons">close</i>
                </div>
            </div>
            <div className="divider"></div> 

            <Formik
                initialValues={{
                    id: id ? id : '',
                    langs_title: title ? title : '',
                    langs_code: code ? code : '',
                    langs_direction: direction ? direction : '',
                    langs_default: defaultLang ? true : false,
                }}
                validationSchema={CreateLanguagesSchema}
                enableReinitialize={true}
                onSubmit={async (values, {resetForm}) => {
                   values.langs_flag = flag;
                    dispatch(saveLanguage(values ,(response)=>{
                        response.error ? setSaveFail({
                            state: true,
                            message: response.message
                        }) : setSaveSuccess(true);
                    }));
                    closeFormSidebar();
                    resetForm({values: ''});
                }}
            >
            {({ values, errors, touched, isSubmitting }) => (
                    <Form className="edit-contact-item mb-5 mt-5">
                        <div className="row">
                            <div className="input-field col s12 Languages-form-avatar-container">
                                {flag ? (
                                    <img src={flag} alt="Languages avatar" className="z-depth-4 circle Languages-form-avatar" height="200" width="200"></img>
                                ): null}
                                <button type="button" className="btn" onClick={openFilesManagerModal}>{_trans('flag',locosBO)}</button>
                            </div>
                            <div className="input-field col s12">
                                <Field id="langs_title" name="langs_title"  type="text" className={errors.langs_title ? "error validate" : "validate"} />
                                <label htmlFor="langs_title" className={title !== '' ? 'active' : ''}>{_trans('Title',locosBO)}</label>
                                {errors.langs_title && touched.langs_title ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.langs_title}</div></small>
                                ) : null}
                            </div>
                            <div className="input-field col s12">
                                <Field id="langs_code" name="langs_code" type="text" className={errors.langs_code ? "error validate" : "validate"} />
                                <label htmlFor="langs_code" className={code !== '' ? 'active' : ''}>{_trans('Code',locosBO)}</label>
                                {errors.langs_code && touched.langs_code ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.langs_code}</div></small>
                                ) : null}
                            </div>
                            <div className="input-field col s12">
                                <p className="mb-2">{_trans('Direction',locosBO)}</p>
                                <p className="mb-1 display-inline mr-3">
                                    <label>
                                        <Field checked={values.langs_direction === 'LTR'}  name="langs_direction" type="radio" value="LTR"  />
                                        <span>LTR</span>
                                    </label>
                                </p>
                                <p className="mb-1 display-inline">
                                    <label>
                                        <Field checked={values.langs_direction === 'RTL'}  name="langs_direction" type="radio" value="RTL" />
                                        <span>RTL</span>
                                    </label>
                                </p>
                             
                            </div>

                            <div className="input-field col s12">
                                <p className="mb-2">{_trans('Set by default',locosBO)}</p>
                                <div className="switch">
                                    <label>
                                        {_trans('No',locosBO)}
                                        <Field name="langs_default" checked={values.langs_default} value="0" type="checkbox"/>
                                            <span className="lever"></span>
                                        {_trans('Yes',locosBO)}
                                    </label>
                                </div>
                            </div>

                            

                        </div>

                        <div className="card-action pl-0 pr-0 right-align">
                            {id === '' ? (
                                <button type="submit" className="btn-small waves-effect waves-light add-contact" disabled={isSubmitting}>
                                    <span>{_trans('Add Language',locosBO)}</span>
                                </button>
                            ) : (
                                <button type="submit" className="btn-small waves-effect waves-light amber darken-4 update-contact" disabled={isSubmitting}>
                                    <span>{_trans('Update Language',locosBO)}</span>
                                </button>
                            )}
                        </div>

                    </Form>
                )}

            </Formik>

            </div>
            </div>
            </div>
        </React.Fragment> ;
}
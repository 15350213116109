import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { _trans } from '../../actions/locoBOActions';

export default function LanguagesCount(  ) {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const { authorizdApps,  loading,  error } = useSelector(state => state.authorizedAppsList);

    useEffect(() => {
        
    }, [authorizdApps]);     

    return (
        <React.Fragment>
            {loading ? <div></div> :  
                error ? <div>{error}</div> : 
                
                    <p className="m-0 text-muted">{authorizdApps.length} {_trans('Applications',locosBO)}</p>
            }
        </React.Fragment>
    );
}
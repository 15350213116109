import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const { CONTACTS_LIST_REQUEST, 
    CONTACTS_LIST_SUCCESS, 
    CONTACTS_LIST_FAIL,
    CONTACTS_SAVE_REQUEST,
    CONTACTS_SAVE_SUCCESS,
    CONTACTS_SAVE_FAIL, 
    CONTACTS_DELETE_REQUEST,
    CONTACTS_DELETE_SUCCESS,
    CONTACTS_DELETE_FAIL,
    SELECTED_CONTACT,
    CONTACTS_SIDEBAR_IS_TOGGLED} = require("../constants/contactsConstants");

const listContacts = () => async (dispatch) => {
    try {
        dispatch({ type: CONTACTS_LIST_REQUEST });
        const { data } = await axios({
                method: 'get',
                url: apiProxy+"/api/contacts",
                headers
            });
        dispatch({ type: CONTACTS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CONTACTS_LIST_FAIL, payload: error.message });
    }
    
}

const saveContact = (contact, callback) => async (dispatch) => {

    try {

        if(!contact.id){
            dispatch({ type: CONTACTS_SAVE_REQUEST, payload: {contact} });
            const { data } = await axios({
                method: 'post',
                data: contact,
                url: apiProxy+"/api/contacts",
                headers
            });
            callback(data);
            dispatch({ type: CONTACTS_SAVE_SUCCESS, payload: data });
        }else{
            dispatch({ type: CONTACTS_SAVE_REQUEST, payload: {contact} });
            const { data } = await axios({
                method: 'patch',
                data: contact,
                url: apiProxy+"/api/contacts/" + contact.id,
                headers
            });
            callback(data);
            dispatch({ type: CONTACTS_SAVE_SUCCESS, payload: data });
        }

    } catch (error) {
        dispatch({ type: CONTACTS_SAVE_FAIL, payload: error.message });
    }
    
}

const deleteContact = (contact, callback) => async (dispatch) => {
    try {
        dispatch({ type: CONTACTS_DELETE_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy+"/api/contacts/" + contact.id,
            headers
        });
        callback(data);
        dispatch({ type: CONTACTS_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: CONTACTS_DELETE_FAIL, payload: error.message });
    }
    
}

const selectedContact = (contact) => async (dispatch) => {
    dispatch({ type: SELECTED_CONTACT, payload: contact });
}

const toggleContactsSidebar = (toggle) => async (dispatch) => {
    dispatch({ type: CONTACTS_SIDEBAR_IS_TOGGLED, payload: toggle });
}

export {listContacts, saveContact, deleteContact, selectedContact, toggleContactsSidebar}
import React, { useEffect } from 'react'
import { Redirect, BrowserRouter} from 'react-router-dom';
import { useSelector } from 'react-redux'
import { userAuthPermissions } from './actions/usersActions';

import Routes from './routes/routes'
import AuthRoutes from './routes/authRoutes'
import { isLoggedIn } from './actions/authActions'
import { useDispatch } from 'react-redux'
import Loading from './components/loader/Loading'


export default function App(props) {
    const dispatch = useDispatch();
    const { loading, user } = useSelector(state => state.login);
    
    useEffect(() => {
        if (!user) {
            dispatch(isLoggedIn());
            dispatch(userAuthPermissions());
        }
    }, [user])

    return (
        <React.Fragment>
            {loading || !user ? <div><Loading /></div> : user.isLoggedIn ?(
                <BrowserRouter>
                    
                        <Routes/>
                        <Redirect to={
                            {
                                pathname: window.location.pathname,
                                state: {
                                    from: props.location
                                }
                            }
                        } />
                    
                </BrowserRouter>
            ) :
                <BrowserRouter>
                        <AuthRoutes />
                        <Redirect to={
                            {
                                pathname: window.location.pathname,
                                state: {
                                    from: props.location
                                }
                            }
                        } />
                    
                </BrowserRouter>
            }
            
        </React.Fragment>
    )
}

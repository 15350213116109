import React from 'react'
import PostTypeCount from './PostTypeCount'
import { listPostType, listTrashedPostType } from '../../actions/postTypeActions';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom"

import { _trans } from '../../actions/locoBOActions';

export default function PostsTypeFiltre( {props} ) {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const dispatch = useDispatch();
    let history = useHistory();

    return (
        <React.Fragment>
            <div className="sidebar-left sidebar-fixed">
            <div className="sidebar">
            <div className="sidebar-content">
            <div className="sidebar-header">
                <div className="sidebar-details">
                    <h5 className="m-0 sidebar-title"><i className="material-icons app-header-icon text-top">art_track</i> {_trans('Posts type',locosBO)}
                </h5>
                <div className="mt-10 pt-2">
                    <p className="m-0 subtitle font-weight-700">{_trans('Count Posts type',locosBO)}</p>
                    <PostTypeCount />
                </div>
                </div>
            </div>
            <div id="sidebar-list" className="sidebar-menu list-group position-relative ps ps--active-y">
                <div className="sidebar-list-padding app-sidebar" id="contact-sidenav">
                    <ul className="contact-list display-grid">
                        <li className="sidebar-title">Filters</li>

                        <li className={typeof props.match.params.trush === "undefined" ? "active" : ""} onClick={() => {
                                dispatch(listPostType(() => {
                                    history.push('/post-type')
                                }))
                            }}><a className="text-sub" ><i className="material-icons mr-2">art_track </i> {_trans('All types',locosBO)}</a>
                        </li>

                        <li className={typeof props.match.params.trush !== "undefined" ? "active" : ""} onClick={() => {
                                dispatch(listTrashedPostType(() => {
                                    history.push('/post-type/trush')
                                }))
                            }}><a className="text-sub" ><i className="material-icons mr-2">delete_sweep </i> {_trans('Trash',locosBO)}</a>
                        </li>

                    </ul>
                </div>
            </div>
            <a data-target="contact-sidenav" className="sidenav-trigger hide-on-large-only"><i
                className="material-icons">menu</i></a>
            </div>
            </div>
            </div>
        </React.Fragment>
    );
}
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FileManagerContent from './FileManagerContent';
import FileManagerFiltres from './FileManagerFiltres';
import { listFiles, toggleFilesManagerModal, selectedFile, selectedMultiFiles } from '../../actions/fileManagerActions';

export default function FileManagerModal () {

  const dispatch = useDispatch();

  const filesManagerToggleModal = useSelector(state => state.toggleFilesManagerModal);
    
  useEffect(() => {
      dispatch(listFiles());
  }, []);

  const closeFilesManagerModal = () => {
    dispatch( toggleFilesManagerModal(false) );
    dispatch( selectedFile(null) );
    dispatch( selectedMultiFiles([]) );
  }

  return (
    filesManagerToggleModal.toggle ?
      <div className= {filesManagerToggleModal.toggle ? "files-manager-modal show" : "files-manager-modal"} >
          <div className='files-manager-modal-body'>
              <button type="button" className="files-manager-modal-btn-close" onClick={closeFilesManagerModal}><i className="material-icons">close</i></button>
              <div className="section app-file-manager-wrapper">
                <FileManagerFiltres />
                <FileManagerContent />
              </div>
            </div>
      </div>
   : null
  );
}



export const menuItems = [{
        icon: 'radio_button_unchecked',
        label: 'Dashboard',
        to: '/',
        key: 'DASHBOARD',
    },
    {
        icon: 'people',
        label: 'Utilisateurs',
        to: '#',
        key: 'USERS_ROOT',
        content: [{
                icon: 'face',
                label: 'Utilisateurs',
                to: '/users',
                key: 'USERS'
            },
            {
                icon: 'verified_user',
                label: 'Les rôles',
                to: '/roles',
                key: 'ROLES'
            },
            {
                icon: 'tune',
                label: 'Les permissions',
                to: '/permissions',
                key: 'PERMISSIONS'
            }

        ],
    },
    {
        icon: 'view_list',
        label: 'Categories',
        to: '#',
        key: 'CATEGORIES',
        content: [{
                icon: 'playlist_add',
                label: 'Categories',
                to: '/categories',
                key: 'CATEGORIES'
            },
            {
                icon: 'playlist_add',
                label: 'Catégories elements',
                to: '/category-items',
                key: 'CATEGORIES_ITEMS'
            }
        ],
    },
    {
        icon: 'perm_media',
        label: 'Gestionnaire de fichiers',
        to: '/files-manager',
        key: 'FILE_MANAGER'
    },
    {
        icon: 'art_track',
        label: 'Type de poste',
        to: '/post-type'
    },
    {
        icon: 'view_compact',
        label: 'Modèles des postes',
        to: '/post-templates',
        key: 'POST_TEMPLATES'
    },
    {
        icon: 'menu',
        label: 'Menus',
        to: '#',
        key: 'MENUS_ROOT',
        content: [{
                icon: 'featured_play_list',
                label: 'List des Menus',
                to: '/menus',
                key: 'MENUS'
            },
            {
                icon: 'format_list_bulleted',
                label: 'Menu elements',
                to: '/menu-items',
                key: 'MENU_ITEMS'
            }
        ],
    },
    {
        icon: 'translate',
        label: 'Traduction',
        to: '#',
        key: 'LANGUAGES_ROOT',
        content: [{
                icon: 'language',
                label: 'Langages',
                to: '/languages',
                key: 'LANGUAGES'
            },
            {
                icon: 'chrome_reader_mode',
                label: 'Loco',
                to: '/loco-translate',
                key: 'LOCO_TRANSLATE'
            },
            {
                icon: 'chrome_reader_mode',
                label: 'Loco BO',
                to: '/locobo-translate',
                key: 'LOCOBO_TRANSLATE'
            }
        ],
    },
    {
        icon: 'security',
        label: 'Applications autorisées',
        to: '/authorized-apps',
        key: 'API'
    }

]
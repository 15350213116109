import React, {  useState,useEffect } from 'react';
import {  Field } from 'formik';

import { useSelector, useDispatch } from 'react-redux'

import { _trans } from '../../../actions/locoBOActions';

export default function UrlField(props) {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO)
    
    let item = props.item;
    const [title, setTitle] = useState("");
    const [className, setClassName] = useState("");
    const [payload, setPayload] = useState("");
    useEffect(() => {
        setTitle(item.title)
        setPayload(item.payload)
        setClassName(item.className)
    },[item])

    return (
        <React.Fragment>
            <div className="collapsible-body">
                <div className="row">
                    <div className="input-field col m12 s12">
                        <Field name={`items[${props.index}].title`} value={title} type="text" onChange={(e)=>{
                           item.title = e.target.value;
                           setTitle(e.target.value);
                        }}  />
                        <label htmlFor="name" className={title? "active" : ""}>{_trans('Title',locosBO)}</label>
                    </div>
                    <div className="input-field col m12 s12">
                        <Field name={`items[${props.index}].className`} value={className} type="text"  onChange={(e)=>{
                           item.className = e.target.value;
                           setClassName(e.target.value);
                        }} />
                        <label htmlFor="name" className={className? "active" : ""}>Css Class</label>
                    </div>
                    <div className="input-field col m12 s12">
                        <Field name={`items[${props.index}].payload`} value={payload} type="text"  onChange={(e)=>{
                           item.payload = e.target.value;
                           setPayload(e.target.value);
                        }} />
                        <label htmlFor="name">{_trans('Link',locosBO)}</label>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

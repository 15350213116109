const { CONTACTS_LIST_REQUEST, 
    CONTACTS_LIST_SUCCESS, 
    CONTACTS_LIST_FAIL,
    CONTACTS_SAVE_REQUEST,
    CONTACTS_SAVE_SUCCESS,
    CONTACTS_SAVE_FAIL, 
    CONTACTS_DELETE_REQUEST,
    CONTACTS_DELETE_SUCCESS,
    CONTACTS_DELETE_FAIL,
    SELECTED_CONTACT,
    CONTACTS_SIDEBAR_IS_TOGGLED} = require("../constants/contactsConstants");

function contactsListReducer(state = {loading:true, contacts:[]}, action){

switch(action.type){
    case CONTACTS_LIST_REQUEST:
        return {loading : true};
    case CONTACTS_LIST_SUCCESS:
        return {loading: false, contacts: action.payload};
    case CONTACTS_LIST_FAIL:
        return {loading: false, error: action.payload};
    default:
        return state;
}

}

function contactsSaveReducer(state = {contacts:[]}, action){

switch(action.type){
    case CONTACTS_SAVE_REQUEST:
        return {loading : true};
    case CONTACTS_SAVE_SUCCESS:
        return {loading: false, success: true, contacts: action.payload};
    case CONTACTS_SAVE_FAIL:
        return {loading: false, error: action.payload};
    default:
        return state;
}

}

function contactsDeleteReducer(state = {contacts:[]}, action){

switch(action.type){
    case CONTACTS_DELETE_REQUEST:
        return {loading : true};
    case CONTACTS_DELETE_SUCCESS:
        return {loading: false, success: true, contacts: action.payload};
    case CONTACTS_DELETE_FAIL:
        return {loading: false, error: action.payload};
    default:
        return state;
}

}

function selectedContactReducer(state = { contact: null }, action) {

switch (action.type) {
    case SELECTED_CONTACT:
        return { contact: action.payload };
    default:
        return state;
}

}

function toggleContactsSidebarReducer(state = { toggle: false }, action) {

switch (action.type) {
    case CONTACTS_SIDEBAR_IS_TOGGLED:
        return { toggle: action.payload };
    default:
        return state;
}

}


export {contactsListReducer, contactsSaveReducer, contactsDeleteReducer, selectedContactReducer, toggleContactsSidebarReducer}
import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const {
    MENUS_LIST_REQUEST,
    MENUS_LIST_SUCCESS,
    MENUS_LIST_FAIL,
    MENUS_SAVE_REQUEST,
    MENUS_SAVE_SUCCESS,
    MENUS_SAVE_FAIL,
    MENUS_DELETE_REQUEST,
    MENUS_DELETE_SUCCESS,
    MENUS_DELETE_FAIL,
    SELECTED_MENU,
    MENUS_SIDEBAR_IS_TOGGLED } = require("../constants/menusConstants");

const listMenus = (cb=null) => async (dispatch) => {
    try {
        dispatch({ type: MENUS_LIST_REQUEST });
        const { data } = await axios({
            method: 'get',
            url: apiProxy+'/api/menus',
            headers
        });
        typeof cb == "function" && cb(data);
        dispatch({ type: MENUS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: MENUS_LIST_FAIL, payload: error.message });
    }

}

const saveMenu = (menu, callback) => async (dispatch) => {


    if (!menu.id) {
        dispatch({ type: MENUS_SAVE_REQUEST, payload: { menu } });
        axios({
            method: 'post',
            url: apiProxy+"/api/menus",
            headers,
            data: menu
        }).then(data => {
            callback({
                error: false,
                data: data,
                message: null
            });
            dispatch({ type: MENUS_SAVE_SUCCESS, payload: data });
        }).catch(err => {
            callback({
                error: true,
                data: null,
                message: err.response.data.message
            });
            dispatch({ type: MENUS_SAVE_FAIL, payload: err.response.data.message });
        });

    } else {
        dispatch({ type: MENUS_SAVE_REQUEST, payload: { menu } });
        axios({
            method: 'patch',
            url: apiProxy+"/api/menus/" + menu.id,
            headers,
            data: menu
        }).then(data => {
            callback({
                error: false,
                data: data,
                message: null
            });
            dispatch({ type: MENUS_SAVE_SUCCESS, payload: data });
        }).catch(err => {
            callback({
                error: true,
                data: null,
                message: err.response.data.message
            });
            dispatch({ type: MENUS_SAVE_FAIL, payload: err.response.data.message });
        });

    }


}

const deleteMenu = (menu, callback) => async (dispatch) => {
    try {
        dispatch({ type: MENUS_DELETE_REQUEST });
        const { data } = await axios.delete(apiProxy+"/api/menus/" + menu.id, headers);
        callback(data);
        dispatch({ type: MENUS_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: MENUS_DELETE_FAIL, payload: error.message });
    }

}

const selectedMenu = (menu) => async (dispatch) => {
    dispatch({ type: SELECTED_MENU, payload: menu });
}

const toggleMenusSidebar = (toggle) => async (dispatch) => {
    dispatch({ type: MENUS_SIDEBAR_IS_TOGGLED, payload: toggle });
}

export { listMenus, saveMenu, deleteMenu, selectedMenu, toggleMenusSidebar }
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, Formik, FieldArray} from 'formik'

import * as Yup from 'yup';
import Errors from '../../validations/Errors';
import { toast } from 'react-toastify';
import {listContacts, saveContact, deleteContact, selectedContact, toggleContactsSidebar} from '../../actions/contactsActions';
import { userAuthPermissions } from '../../actions/usersActions';

import { _trans } from '../../actions/locoBOActions';

export default function FormComponent() {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const CreateContactsSchema = Yup.object().shape({
        name: Yup.string().min(3, Errors.min({ name: _trans('Name',locosBO), number: "3" })).required(Errors.required({ name: _trans('Name',locosBO)}))
    })

    const dispatch = useDispatch();

    const [id, setId] = useState('');

    const [selectAll , setSelectAll] = useState(false);

    const [allCheckboxes , setCheckboxes] = useState([]);

    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [source, setSource] = useState('');
    const [info, setInfo] = useState('');

    const [saveSuccess, setSaveSuccess] = useState(false);

    const contactsToggleSidebar = useSelector(state => state.toggleContactsSidebar);

    const contactSelected = useSelector(state => state.selectedContact);

    const contactsSave = useSelector(state => state.contactsSave);

    const [value, setValue] = useState(0); // integer state
    const { loading : loadingSave, 
            success: successSave, 
            error: errorSave} = contactsSave;

     useEffect(() => {
        if(contactSelected.contact){
                setId(contactSelected.contact.id);
                setName(contactSelected.contact.name);
                setTel(contactSelected.contact.tel);
                setEmail(contactSelected.contact.email);
                setCompany(contactSelected.contact.company);
                setSource(contactSelected.contact.source);
                setInfo(contactSelected.contact.info);
            }else{
                setId('');
                setName('');
                setTel('');
                setEmail('');
                setCompany('');
                setSource('');
                setInfo('');
            }
            
            if(saveSuccess){
                setSaveSuccess(false);
                dispatch(listContacts());
                toast.success(_trans('Contact Saved',locosBO));
            }
    
    }, [contactSelected, saveSuccess]);
 
    const closeFormSidebar = () => {
        dispatch( selectedContact(null) );
        dispatch( toggleContactsSidebar(false) );
    }
    
     const  handleSubmit = (values) =>{
         dispatch(saveContact(values ,(response)=>{
             dispatch(userAuthPermissions());
            setSaveSuccess(true);
        }));
     }

    const selectAllHandler = () => {
        let checkboxes = allCheckboxes;
        for (let i = 0; i < checkboxes.length; i++) {
            checkboxes[i] = !selectAll;
        }
        setCheckboxes(checkboxes);
        setSelectAll(!selectAll);
    }


    return <React.Fragment>
    {loadingSave && <div></div> }
    {errorSave && <div>{errorSave}</div> }
        <div className={contactsToggleSidebar.toggle ? "contact-compose-sidebar show" : "contact-compose-sidebar"}>
        <div className="card quill-wrapper">
        <div className="card-content pt-0">
        <div className="card-header display-flex pb-2">
            <h3 className="card-title contact-title-label">{ id === '' ? _trans('Create New Contact',locosBO) : _trans('Update Contact',locosBO)}</h3>
            <div className="close close-icon" onClick={closeFormSidebar}>
                <i className="material-icons">close</i>
            </div>
        </div>
        <div className="divider"></div> 

        <Formik
            initialValues={{
                id: id ? id : '',
                name: name ? name : '',
                tel: tel ? tel : '',
                email: email ? email : '',
                company: company ? company : '',
                info: info ? info : '',
            }}
            validationSchema={CreateContactsSchema}
            enableReinitialize={true}
            onSubmit={async (values, {resetForm}) => {
                closeFormSidebar();
                values = { ...values};
                setSelectAll(false);
                handleSubmit(values);
                // setContactPermissions([]);
                resetForm({values: ''});
            }}
        >
        {({ values, errors, touched, isSubmitting ,setFieldValue}) => (
                <Form className="edit-contact-item mb-5 mt-5">
                    <div className="row">
                        <div className="input-field col s12">
                            <Field id="contacts_name" name="name" type="text" className={errors.name ? "error validate" : "validate"} />
                            <label htmlFor="contacts_name" className={name !== '' ? 'active' : ''}>{_trans('Name',locosBO)}</label>
                            {errors.name && touched.name ? (
                                <small className="errorTxt2"><div id="cemail-error" className="error">{errors.name}</div></small>
                            ) : null}
                        </div>
                        <div className="input-field col s12">
                            <Field id="contacts_tel" name="tel" type="text" className={errors.tel ? "error validate" : "validate"} />
                            <label htmlFor="tel" className={tel !== '' ? 'active' : ''}>{_trans('Telephone',locosBO)}</label>
                            {errors.tel && touched.tel ? (
                                <small className="errorTxt2"><div id="cemail-error" className="error">{errors.tel}</div></small>
                            ) : null}
                        </div>
                        <div className="input-field col s12">
                            <Field id="contacts_email" name="email" type="text" className={errors.email ? "error validate" : "validate"} />
                            <label htmlFor="email" className={email !== '' ? 'active' : ''}>{_trans('Email',locosBO)}</label>
                            {errors.email && touched.email ? (
                                <small className="errorTxt2"><div id="cemail-error" className="error">{errors.email}</div></small>
                            ) : null}
                        </div>
                        <div className="input-field col s12">
                            <Field id="contacts_company" name="company" type="text" className={errors.company ? "error validate" : "validate"} />
                            <label htmlFor="contacts_company" className={company !== '' ? 'active' : ''}>{_trans('Company',locosBO)}</label>
                            {errors.company && touched.company ? (
                                <small className="errorTxt2"><div id="cemail-error" className="error">{errors.company}</div></small>
                            ) : null}
                        </div>
                        <div className="input-field col s12">
                            <Field id="contacts_info" name="info" type="text" className={errors.info ? "error validate" : "validate"} />
                            <label htmlFor="contacts_info" className={info !== '' ? 'active' : ''}>{_trans('Info',locosBO)}</label>
                            {errors.info && touched.info ? (
                                <small className="errorTxt2"><div id="cemail-error" className="error">{errors.info}</div></small>
                            ) : null}
                        </div>
                        
                    </div>

                    <div className="card-action pl-0 pr-0 right-align">
                        <button type="submit" className="btn-small waves-effect waves-light add-contact" disabled={isSubmitting}>
                            {!id? (
                                <span>{_trans('Save',locosBO)}</span>
                                ) : (
                                <span>{_trans('Edit',locosBO)}</span>
                            )}
                        </button>
                    </div>

                </Form>
            )}

        </Formik>

        </div>
        </div>
        </div>
    </React.Fragment> 
}
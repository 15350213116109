import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Field, Formik } from 'formik'
import * as Yup from 'yup'
import Errors from '../../validations/Errors'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import Select from 'react-select'
import { customStyles } from '../../styles/reactSelect'

import { listCategories, saveCategory, selectedCategory, toggleCategoriesSidebar, findCategoryByLang } from '../../actions/categoriesActions'

import { _trans } from '../../actions/locoBOActions';


export default function CategoriesFormSidebar() {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const CreateCategorySchema = Yup.object().shape({
        categories_title: Yup.string().min(3, Errors.min({ name: _trans('The title',locosBO), number: "3" })).required( Errors.required({ name: _trans('The title',locosBO) }) ),
        categories_slug: Yup.string().required( Errors.required({ name: "slug" }) ),
        id_lang: Yup.number().required(Errors.required({ name: _trans('The language',locosBO) }) ),
        post_types: Yup.array().required(Errors.required({ name: _trans('Post type',locosBO) }) ),
    })
    

    const dispatch = useDispatch();

    const [saveSuccess, setSaveSuccess] = useState(false);
    const [saveFail, setSaveFail] = useState({
        state : false,
        message : null
    });

    const { currentLang } = useSelector(state => state.currentLang);

    const categoriesList = useSelector(state => state.categories);
    const { categories, loading: loadingCategories, error: errorRoles } = categoriesList;


    const categoriesToggleSidebar = useSelector(state => state.toggleCategoriesSidebar);

    const { loading: loadingCategory, category, errorCategory } = useSelector(state => state.selectedCategory);

    const categorySave = useSelector(state => state.categorySave);
    const { loading: loadingSave, success: successSave, error: errorSave } = categorySave;

    const languagesList = useSelector(state => state.languagesList);
    const { languages, loading: langsLoading, error: langsError } = languagesList;

    const postTypeList = useSelector(state => state.postTypeList);
    const { postType, loadingPostType, errorPostType } = postTypeList;

    const postTemplatesList = useSelector(state => state.postTemplatesList);
    const { postTemplates, loadingPostTemplates, errorPostTemplates } = postTemplatesList;
    const [id, setId] = useState('');
    const [titre, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [id_lang, setIdLang] = useState("");
    const [idCatParent, setIdCatParent] = useState(null);
    const [postTypeObjects, setPostTypeObject] = useState('');
    const [postTemplateId, setPostTemplateId] = useState('');


    const languageOptions = [];
    if (languages) {
        languages.filter(cat => {
            let option = {
                label: cat.langs_title,
                value: cat.id
            }
            languageOptions.push(option);
        })
    }

    const categoryOptions = []
    if (categories) {
        categories.filter(cat => {
            let option = {
                label: cat.categories_title,
                value: cat.id_cat
            }
            categoryOptions.push(option);
        })
    }
   
    const postTypeListOptions = []
    if (postType) {
        postType.filter(cat => {
            let option = {
                label: cat.post_type_title,
                value: cat.id
            }
            postTypeListOptions.push(option);
        })
    }
    const postTemplatesOptions = []
    if (postTemplates) {
        postTemplates.filter(post => {
            let option = {
                label: post.postTemplates_name,
                value: post.id
            }
            postTemplatesOptions.push(option);
        })
    }
    
   

    useEffect(() => {
       if(category){
            setId(category.id_cat);
            setTitle(category.categories_title);
            setSlug(category.categories_slug);
            setIdCatParent(category.id_cat_parent);
            setIdLang(category.id_lang);
           setPostTypeObject(category.post_types);
           setPostTemplateId(category.id_post_template);
        }else{
           setId('');
           setTitle('');
           setSlug('');
           if (currentLang) {
               setIdLang(currentLang.id);
           }
           setIdCatParent(null);
           setPostTypeObject('');
           setPostTemplateId('');
        }

       
        
        if(saveSuccess){
            setSaveSuccess(false);
            dispatch(listCategories());
            toast.success( _trans('Category Saved Successfully',locosBO) );
        }
        if (saveFail.state){
            toast.error(saveFail.message);
            dispatch(listCategories());
            setSaveFail({
                state: false,
                message: null
            });
        }
    }, [category, saveSuccess, saveFail, currentLang]);
    


    const handleChangeTitle = (e) => {
        let value = e.target.value;
        let slug = value.toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
        setTitle(value);
        setSlug(slug);
    }
    const handleChangeSlug = (e) => {
        let value = e.target.value;
        let slug = value.toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
        setSlug(slug);
    }
    const closeFormSidebar = () => {
        dispatch( selectedCategory(null) );
        dispatch( toggleCategoriesSidebar(false) );
    }


    return <React.Fragment>
        {loadingCategory && <div><Skeleton count={8} height={50} /></div> }
        {loadingSave && <div><Skeleton count={8} height={50} /></div> }
        {errorSave && <div>{errorSave}</div> }

            <div className={categoriesToggleSidebar.toggle ? "contact-compose-sidebar show" : "contact-compose-sidebar"}>
            <div className="card quill-wrapper">
            <div className="card-content pt-0">
            <div className="card-header display-flex pb-2">
                <h3 className="card-title contact-title-label">{id === '' ? _trans('Create New Category',locosBO) : _trans('Update Category',locosBO) }</h3>
                <div className="close close-icon" onClick={closeFormSidebar}>
                    <i className="material-icons">close</i>
                </div>
            </div>
            <div className="divider"></div> 

            <Formik
                initialValues={{
                    id: id ? id : '',
                    categories_title: titre ? titre : '',
                    categories_slug: slug ? slug : '',
                    id_lang: id_lang ? id_lang : '',
                    id_cat_parent: idCatParent ? idCatParent : '',
                    post_types:  postTypeObjects? postTypeObjects: '',
                    id_post_template: postTemplateId ? postTemplateId: '',
                }}
                validationSchema={CreateCategorySchema}
                enableReinitialize={true}
                onSubmit={async (values, {resetForm}) => {
                    closeFormSidebar();
                    dispatch(saveCategory(values, (response) => {
                        response.error ? setSaveFail({
                            state: true,
                            message: response.message
                        }) : setSaveSuccess(true);
                    }));
                    resetForm({values: ''});
                    setId('');
                    setTitle('');
                    setSlug('');
                    setIdLang(currentLang.id);
                    setIdCatParent(null);
                    setPostTypeObject('');
                    setPostTemplateId('');
                }}
            >
            {({ values, errors, touched, isSubmitting, setFieldValue, setFieldTouched }) => (
                    <Form className="edit-contact-item mb-5 mt-5">
                        <div className="row">
                            <div className="input-field col s12">
                                <Field id="categories_title" name="categories_title" type="text" onBlur={e => handleChangeTitle(e)}  className={errors.categories_title ? "error validate" : "validate"} />
                                <label htmlFor="categories_title" className={titre !== '' ? 'active' : ''}>{_trans('Title',locosBO)}</label>
                                {errors.categories_title && touched.categories_title ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.categories_title}</div></small>
                                ) : null}
                            </div>
                            <div className="input-field col s12">
                            <Field id="categories_slug" name="categories_slug" type="text" className={errors.categories_slug ? "error validate" : "validate"} onChange={e => handleChangeSlug(e)}/>
                                <label htmlFor="categories_slug" className={slug !== '' ? 'active' : ''}>Slug</label>
                                {errors.categories_slug && touched.categories_slug ? (
                                    <small className="errorTxt2"><div id="cemail-error" className="error">{errors.categories_slug}</div></small>
                                ) : null}
                            </div>

                            {
                                langsLoading ? '' :
                                id ? 
                                (
                                    <div className="input-field col m12 s12">
                                        <Select
                                            name="id_lang"
                                            id="id_lang"
                                            onBlur={() => setFieldTouched("id_lang", true)}
                                            value={languageOptions.filter(opt => { return (opt.value == id_lang) })}
                                            onChange={(opt, e) => {
                                                setFieldValue("id_lang", opt?.value);
                                                setIdLang(opt?.value);
                                                dispatch(findCategoryByLang(id,opt?.value,(category)=>{
                                                    setTitle(category.categories_title);
                                                    setSlug(category.categories_slug);
                                                    setIdCatParent(category.id_cat_parent);
                                                }))
                                            }}
                                            blurInputOnSelect={true}
                                            placeholder="Select Language"
                                            isSearchable={true}
                                            isClearable={true}
                                            styles={customStyles}
                                            options={languageOptions}
                                            error={errors.id_lang}
                                            touched={touched.id_lang}
                                        />
                                        <label htmlFor="id_lang" className="active">{_trans('Languages',locosBO)}</label>
                                        {errors.id_lang && touched.id_lang ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.id_lang}</div></small>
                                        ) : null}
                                    </div>
                                ) : ""
                            }
                            {
                            !loadingCategories && categories.length  ?(
                                <div className="input-field col s12">
                                        <Select
                                            name="id_cat_parent"
                                            id="id_cat_parent"
                                            onBlur={() => setFieldTouched("id_cat_parent", true)}
                                            value={categoryOptions.filter(opt => { return (opt.value === idCatParent) })}
                                            onChange={(opt, e) => {
                                                setFieldValue("id_cat_parent", opt?.value);
                                                setIdCatParent(opt?.value);
                                            }}
                                            blurInputOnSelect={true}
                                            placeholder="Select Category parent"
                                            isSearchable={true}
                                            isClearable={true}
                                            styles={customStyles}
                                            options={categoryOptions}
                                            error={errors.id_cat_parent}
                                            touched={touched.id_cat_parent}
                                        />
                                    <label htmlFor="id_cat_parent" className='active mb-3'>{_trans('Category parent',locosBO)}</label>
                                    {errors.id_cat_parent && touched.id_cat_parent ? (
                                        <small className="errorTxt2"><div id="cemail-error" className="error">{errors.id_cat_parent}</div></small>
                                    ) : null}
                                </div>
                            ):""
                            }
                            {
                            !loadingPostType && postTypeListOptions.length  ?(
                                <div className="input-field col s12">
                                        <Select
                                            name="post_types"
                                            id="post_types"
                                            onBlur={() => setFieldTouched("post_types", true)}
                                            value={postTypeObjects}
                                            onChange={(opt, e) => {
                                                if (!opt) {
                                                    opt = "";
                                                }
                                                setFieldValue("post_types", opt);
                                                setPostTypeObject(opt);
                                            }}
                                            // blurInputOnSelect={true}
                                            isMulti={true}
                                            placeholder="Select Type de post"
                                            isSearchable={true}
                                            isClearable={true}
                                            styles={customStyles}
                                            options={postTypeListOptions}
                                            error={errors.id_cat_parent}
                                            touched={touched.id_cat_parent}
                                        />
                                    <label htmlFor="post_types" className='active mb-3'>{_trans('Post type',locosBO)}</label>
                                        {errors.post_types && touched.post_types ? (
                                        <small className="errorTxt2"><div id="cemail-error" className="error">{errors.post_types}</div></small>
                                    ) : null}
                                </div>
                            ):""
                            }
                            {
                            !loadingPostTemplates && postTemplatesOptions.length  ?(
                                <div className="input-field col s12">
                                        <Select
                                            name="id_post_template"
                                            id="id_post_template"
                                            onBlur={() => setFieldTouched("id_post_template", true)}
                                            value={postTemplatesOptions.filter(opt => { return (opt.value === postTemplateId) })}
                                            onChange={(opt, e) => {
                                                setFieldValue("id_post_template", opt?.value);
                                                setPostTemplateId(opt?.value);
                                            }}
                                            blurInputOnSelect={true}
                                            placeholder="Select post template"
                                            isSearchable={true}
                                            isClearable={true}
                                            styles={customStyles}
                                            options={postTemplatesOptions}
                                            error={errors.id_cat_parent}
                                            touched={touched.id_cat_parent}
                                        />
                                    <label htmlFor="id_post_template" className='active mb-3'>{_trans('Post template',locosBO)}</label>
                                        {errors.id_post_template && touched.id_post_template ? (
                                        <small className="errorTxt2"><div id="cemail-error" className="error">{errors.id_post_template}</div></small>
                                    ) : null}
                                </div>
                            ):""
                            }
                        </div>
                      

                        <div className="card-action pl-0 pr-0 right-align">
                            {id === '' ? (
                                <button type="submit" className="btn-small waves-effect waves-light add-contact" disabled={isSubmitting}>
                                    <span>{_trans('Add Category',locosBO)}</span>
                                </button>
                            ) : (
                                <button type="submit" className="btn-small waves-effect waves-light amber darken-4 update-contact" disabled={isSubmitting}>
                                    <span>{_trans('Update Category',locosBO)}</span>
                                </button>
                            )}
                        </div>

                    </Form>
                )}

            </Formik>

            </div>
            </div>
            </div>
        </React.Fragment> ;
}

import axios from 'axios';
import { headers } from '../config/ApiHeaders'
import { apiProxy } from '../config/Settings'

const { LEADS_LIST_REQUEST, 
    LEADS_LIST_SUCCESS, 
    LEADS_LIST_FAIL,
    LEADS_SAVE_REQUEST,
    LEADS_SAVE_SUCCESS,
    LEADS_SAVE_FAIL, 
    LEADS_DELETE_REQUEST,
    LEADS_DELETE_SUCCESS,
    LEADS_DELETE_FAIL,
    SELECTED_LEAD,
    LEADS_SIDEBAR_IS_TOGGLED} = require("../constants/leadsConstants");

const listLeads = () => async (dispatch) => {
    try {
        dispatch({ type: LEADS_LIST_REQUEST });
        const { data } = await axios({
                method: 'get',
                url: apiProxy+"/api/leads",
                headers
            });
        dispatch({ type: LEADS_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: LEADS_LIST_FAIL, payload: error.message });
    }
    
}

const saveLead = (lead, callback) => async (dispatch) => {

    try {

        if(!lead.id){
            dispatch({ type: LEADS_SAVE_REQUEST, payload: {lead} });
            const { data } = await axios({
                method: 'post',
                data: lead,
                url: apiProxy+"/api/Leads",
                headers
            });
            callback(data);
            dispatch({ type: LEADS_SAVE_SUCCESS, payload: data });
        }else{
            dispatch({ type: LEADS_SAVE_REQUEST, payload: {lead} });
            const { data } = await axios({
                method: 'patch',
                data: lead,
                url: apiProxy+"/api/Leads/" + lead.id,
                headers
            });
            callback(data);
            dispatch({ type: LEADS_SAVE_SUCCESS, payload: data });
        }

    } catch (error) {
        dispatch({ type: LEADS_SAVE_FAIL, payload: error.message });
    }
    
}

const deleteLead = (lead, callback) => async (dispatch) => {
    try {
        dispatch({ type: LEADS_DELETE_REQUEST });
        const { data } = await axios({
            method: 'delete',
            url: apiProxy+"/api/leads/" + lead.id,
            headers
        });
        callback(data);
        dispatch({ type: LEADS_DELETE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: LEADS_DELETE_FAIL, payload: error.message });
    }
    
}

const selectedLead = (lead) => async (dispatch) => {
    dispatch({ type: SELECTED_LEAD, payload: lead });
}

const toggleLeadsSidebar = (toggle) => async (dispatch) => {
    dispatch({ type: LEADS_SIDEBAR_IS_TOGGLED, payload: toggle });
}

export {listLeads, saveLead, deleteLead, selectedLead, toggleLeadsSidebar}
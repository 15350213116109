import axios from 'axios';
import { apiProxy } from '../config/Settings'
const { 
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    IS_LOGGED_IN_REQUEST,
    IS_LOGGED_IN_SUCCESS,
    IS_LOGGED_IN_FAIL,
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    VERIFY_RESET_PASSWORD_LINK_REQUEST,
    VERIFY_RESET_PASSWORD_LINK_SUCCESS,
    VERIFY_RESET_PASSWORD_LINK_FAIL
 } = require("../constants/authConstants");

//  login action 

const login = (authData, callback) => async (dispatch) => {

        dispatch({ type: LOGIN_REQUEST, payload: { authData } });
        axios.post(apiProxy+"/api/auth/login", authData).then(response => {
            callback({
                error: false,
                data: response.data,
                message: null
            });
            if (response.data.remember_me) {
                window.localStorage.setItem('AUTH_TOKEN', response.data.token);
            }else{
                window.sessionStorage.setItem('AUTH_TOKEN', response.data.token);
            }
            dispatch({ type: LOGIN_SUCCESS, payload: response });
        }).catch(err => {
            callback({
                error: true,
                data: null,
                message: err.response.data.message
            });
            dispatch({ type: LOGIN_FAIL, payload: err.response.data.message });
        });

}

//  logOut action 

const logOut = (cb) => async (dispatch) => {
        window.sessionStorage.clear();
        window.localStorage.clear();
        dispatch({
            type: IS_LOGGED_IN_FAIL, payload: {
                isLoggedIn: false,
                token: null
            }
        });
        cb();

}


// Check if use still loggedin 

const isLoggedIn = () => async (dispatch) => {
        const tokensessionStorage = window.sessionStorage.getItem("AUTH_TOKEN");
        const tokenLocalStorage = window.localStorage.getItem("AUTH_TOKEN");
        const token = tokenLocalStorage ? tokenLocalStorage : tokensessionStorage;
         if (!token) {
             dispatch({
                 type: IS_LOGGED_IN_FAIL, payload: {
                     isLoggedIn: false,
                     token: null
                 }
             });
             return;
         }
         dispatch({
            type: IS_LOGGED_IN_REQUEST, 
            payload: {
                isLoggedIn: false,
                token: null
            } 
        });
    axios.post(apiProxy+"/api/auth/verify-token", {
            token: token
        }).then(response => {
            dispatch({
                type: IS_LOGGED_IN_SUCCESS, payload: {
                    isLoggedIn: true,
                    token: response.data
                } 
             });
        }).catch(err => {
            dispatch({ type: IS_LOGGED_IN_FAIL, payload: err });
        });

}


// Forgot password action


const forgotPassword = (email, callback) => async (dispatch) => {

    dispatch({ type: FORGOT_PASSWORD_REQUEST, payload: { email } });
    axios({
        data: email,
        method: 'post',
        url: apiProxy+ '/api/auth/forgot-password',
        headers: { 'client-host': window.location.host }
    }).then(response => {
        callback({
            error: false,
            data: response.data,
            message: null
        });
        dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: response });
    }).catch(err => {
        callback({
            error: true,
            data: null,
            message: err.response.data
        });
        dispatch({ type: FORGOT_PASSWORD_FAIL, payload: err.response.data.message });
    });

}

//verify Reset Password Link

const verifyResetPasswordLink = (hash, callback) => async (dispatch) => {

    dispatch({ type: VERIFY_RESET_PASSWORD_LINK_REQUEST, payload: { hash } });
    axios.post(apiProxy+"/api/auth/verify-reset-password-link", hash).then(response => {
        callback({
            error: false,
            data: response.data,
            message: null
        });
        dispatch({ type: VERIFY_RESET_PASSWORD_LINK_SUCCESS, payload: response });
    }).catch(err => {
        callback({
            error: true,
            data: err.response.data,
            message: err.response.data.message
        });
        dispatch({ type: VERIFY_RESET_PASSWORD_LINK_FAIL, payload: err.response.data.message });
    });

}

// reset Password
const resetPassword = (dataForm, callback) => async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_REQUEST, payload: { dataForm } });
    axios.post(apiProxy+"/api/auth/reset-password", dataForm).then(response => {
        callback({
            error: false,
            data: response.data,
            message: null
        });
        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: response });
    }).catch(err => {
        callback({
            error: true,
            data: err.response.data,
            message: err.response.data.message
        });
        dispatch({ type: RESET_PASSWORD_FAIL, payload: err.response.data.message });
    });

}




export { login, isLoggedIn, logOut, forgotPassword, verifyResetPasswordLink, resetPassword}
const { LOCOS_BO_LIST_REQUEST,
    LOCOS_BO_LIST_SUCCESS,
    LOCOS_BO_LIST_FAIL,
    LOCOS_BO_TRANSLATE_REQUEST,
    LOCOS_BO_TRANSLATE_SUCCESS,
    LOCOS_BO_TRANSLATE_FAIL,
    PATCH_BO_LOCOS_TRANSLATE_REQUEST,
    PATCH_BO_LOCOS_TRANSLATE_SUCCESS,
    PATCH_BO_LOCOS_TRANSLATE_FAIL,
} = require("../constants/locosBOConstants");

function listLocosBOReducer(state = { loading: false, locosBO: [] }, action) {

    switch (action.type) {
        case LOCOS_BO_LIST_REQUEST:
            return { loading: true };
        case LOCOS_BO_LIST_SUCCESS:
            return { loading: false, locosBO: action.payload };
        case LOCOS_BO_LIST_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function getLocoBOTranslateReducer(state = { loading: false, locosBOTranslate: [] }, action) {

    switch (action.type) {
        case LOCOS_BO_TRANSLATE_REQUEST:
            return { loading: true };
        case LOCOS_BO_TRANSLATE_SUCCESS:
            return { loading: false, locosBOTranslate: action.payload };
        case LOCOS_BO_TRANSLATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}

function patchLocoBOReducer(state = { loading: false, patchedLocoBO: [] }, action) {

    switch (action.type) {
        case PATCH_BO_LOCOS_TRANSLATE_REQUEST:
            return { loading: true };
        case PATCH_BO_LOCOS_TRANSLATE_SUCCESS:
            return { loading: false, patchedLocoBO: action.payload };
        case PATCH_BO_LOCOS_TRANSLATE_FAIL:
            return { loading: false, error: action.payload };
        default:
            return state;
    }

}



export { listLocosBOReducer, getLocoBOTranslateReducer, patchLocoBOReducer }
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { _trans } from '../../actions/locoBOActions';

export default function PostsTypeCount(  ) {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const postTypeList = useSelector(state => state.postTypeList);
    const { postType, 
            loading,
            error } = postTypeList;

    useEffect(() => {
        
    }, [postType]);     

    return (
        <React.Fragment>
            {loading ? <div></div> :  
                error ? <div>{error}</div> : 
                
                <p className="m-0 text-muted">{postType.length} {_trans('Post type',locosBO)}</p>
            }
        </React.Fragment>
    );
}
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Field, Formik } from 'formik'
import * as Yup from 'yup';
import Errors from '../../validations/Errors';
import { toast } from 'react-toastify';
import { listPostType, savePostType, selectedPostType, togglePostTypeSidebar } from '../../actions/postTypeActions';

import { _trans } from '../../actions/locoBOActions';


export default function PostTypeFormSidebar() {

    const { locosBO, loading: locosBOLoading, error: locosBOError} = useSelector(state => state.listLocosBO) 

    const CreatePostTypeSchema = Yup.object().shape({
        name: Yup.string().min(3, Errors.min({ name: _trans('The name',locosBO), number: "3" })).required(Errors.required({ name: _trans('The name',locosBO) }))
    })

    const dispatch = useDispatch();

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [slug, setSlug] = useState('');
    

    const [saveSuccess, setSaveSuccess] = useState(false);

    const postTypeToggleSidebar = useSelector(state => state.togglepostTypeSidebar);

    const postTypeSelected = useSelector(state => state.selectedPostType);

    const postTypeSave = useSelector(state => state.postTypeSave);
    const { loading: loadingSave,
        success: successSave,
        error: errorSave } = postTypeSave;

    useEffect(() => {

        if (postTypeSelected.postType) {
            setId(postTypeSelected.postType.id);
            setName(postTypeSelected.postType.post_type_title);
            setSlug(postTypeSelected.postType.post_type_slug);

        } else {
            setId('');
            setName('');
            setSlug('');
        }

        if (saveSuccess) {
            setSaveSuccess(false);
            dispatch(listPostType());
            toast.success( _trans('Post Type Saved',locosBO) );
        }

    }, [postTypeSelected, saveSuccess]);

    const closeFormSidebar = () => {
        dispatch(selectedPostType(null));
        dispatch(togglePostTypeSidebar(false));
    }

    const handleChangeTitle = (e) => {
        let value = e.target.value;
        let slug = value.toLowerCase()
                            .replace(/ /g,'-')
                            .replace(/[^\w-]+/g,'');
        setName(value);
        setSlug(slug);
    }

    const handleChangeSlug = (e) => {
        let value = e.target.value;
        let slug = value.toLowerCase()
                            .replace(/ /g,'-')
                            .replace(/[^\w-]+/g,'');
        setSlug(slug);
    }

    const handleSubmit = (values) => {
        dispatch(savePostType(values, (response) => {
            setSaveSuccess(true);
        }));
    }

    return <React.Fragment>
        {loadingSave && <div></div>}
        {errorSave && <div>{errorSave}</div>}

        <div className={postTypeToggleSidebar.toggle ? "contact-compose-sidebar show" : "contact-compose-sidebar"}>
            <div className="card quill-wrapper">
                <div className="card-content pt-0">
                    <div className="card-header display-flex pb-2">
                        <h3 className="card-title contact-title-label">{id === '' ? "Create New PostType" : "Update PostType"}</h3>
                        <div className="close close-icon" onClick={closeFormSidebar}>
                            <i className="material-icons">close</i>
                        </div>
                    </div>
                    <div className="divider"></div>

                    <Formik
                        initialValues={{
                            id: id ? id : '',
                            name: name ? name : '',
                            slug: slug ? slug : ''
                        }}
                        validationSchema={CreatePostTypeSchema}
                        enableReinitialize={true}
                        onSubmit={async (values, { resetForm }) => {
                            closeFormSidebar();
                            handleSubmit(values);
                            resetForm({ values: '' });
                        }}
                    >
                        {({ values, errors, touched, isSubmitting }) => (
                            <Form className="edit-contact-item mb-5 mt-5" >
                                <div className="row">
                                    <div className="input-field col s12">
                                        <Field id="post_type_title" name="name" type="text" className={errors.post_type_title ? "error validate" : "validate"} onChange= {e => handleChangeTitle(e) }  />
                                        <label htmlFor="post_type_title" className={name !== '' ? 'active' : ''}>{_trans('Title',locosBO)}</label>
                                        {errors.post_type_title && touched.postType_name ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.post_type_title}</div></small>
                                        ) : null}
                                    </div>

                                    <div className="input-field col s12">
                                        <Field id="post_type_slug" name="slug" type="text" className={errors.post_type_slug ? "error validate" : "validate"} onChange= {e => handleChangeSlug(e) } />
                                        <label htmlFor="post_type_slug" className={slug !== '' ? 'active' : ''}>Slug</label>
                                        {errors.post_type_slug && touched.post_type_slug ? (
                                            <small className="errorTxt2"><div id="cemail-error" className="error">{errors.post_type_slug}</div></small>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="card-action pl-0 pr-0 right-align">
                                    {id === '' ? (
                                        <button type="submit" className="btn-small waves-effect waves-light add-contact" disabled={isSubmitting}>
                                            <span>{_trans('Add PostType',locosBO)}</span>
                                        </button>
                                    ) : (
                                        <button type="submit" className="btn-small waves-effect waves-light amber darken-4 update-contact" disabled={isSubmitting}>
                                            <span>{_trans('Update PostType',locosBO)}</span>
                                        </button>
                                    )}
                                </div>

                            </Form>
                        )}

                    </Formik>

                </div>
            </div>
        </div>
    </React.Fragment>
}
import React, { useEffect } from 'react';
// import { Link, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Nav from './Navbar/Nav'

function Header(){
    
    window.initPlugins();
    
    return (
        <React.Fragment>
            {/* <!-- BEGIN: Header--> */}
            <header className="page-topbar" id="header">
                <div className="navbar navbar-fixed"> 
                    <Nav />
                </div>
            </header>
            {/* <!-- END: Header--> */}
        </React.Fragment>
    );
}

export default Header;